import { useState } from "react";
import { sha1 } from "crypto-hash";

// react-router-dom components
import { Link } from "react-router-dom";
import { GoogleLogin } from '@react-oauth/google';

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images

import bgImage from "assets/images/1463518.jpg";

function Basic() {
  const [ profile, setProfile ] = useState([]);
  const queryParameters = new URLSearchParams(window.location.search);
  const type = queryParameters.get("type");
  const id = queryParameters.get("id");
  const amount = queryParameters.get("amount");
  const typeofmain = queryParameters.get("typeof");
  console.log(typeofmain, 'line no 39');
  console.log(type, 'line no 36');

  const activitydata = async () => {
    const email = document.getElementById('inputemail').value;
    const apihandler = window.harestec_service + 'adduser_query_areasAPI'
    const data = {
      "email": email,
      "type": "login"
    }
    const settings = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await fetch(apihandler, settings);
  }
  const fetchApiData = async (url) => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      localStorage.clear();
      const res = await fetch(url, settings);
      const apidata = await res.json();
      const fdata = apidata.list;
      localStorage.setItem('client', JSON.stringify(fdata?.map((i) => i)));
      const clientdatastr = localStorage.getItem('client');
      const clientdata = JSON.parse(clientdatastr);
      activitydata();
      console.log(clientdata);
      if (clientdata.length == 1 && clientdata[0].user_type_id == '3') {
        if(type == "nonbuy"){
          window.open('/resources', '_self');
        }else{
          window.open(`/paymentpage?id=${id}&amount=${amount}&type=${typeofmain}`, '_self');
        }
      } else {
        window.open(window.urlmain + 'authentication/sign-inclient', '_self');
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log(profile);

  function signIn() {
    const email = document.getElementById('inputemail').value;
    const cpassword = document.getElementById('inputpassword').value;
    const hashPass = async () => {
      const password = await sha1(cpassword)
      const api = window.harestec_service + `userLogin?email=${email}&password=${password}`;
      fetchApiData(api);
    }
    hashPass();
  }

  const responseMessage = async (response) => {
    console.log(response, 'line no 85');
    const [headerBase64, payloadBase64] = response.credential.split('.');
    const decodedHeader = JSON.parse(atob(headerBase64));
    const decodedPayload = JSON.parse(atob(payloadBase64));
    console.log(decodedHeader, decodedPayload, 'line no 88');
    const userdata = {
      "username": decodedPayload.name,
      "email": decodedPayload.email,
      "email_verfied": decodedPayload.email_verified,
      "user_type_id": 3,
      "type": "google"
    }
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userdata)
    };
    try {
      localStorage.clear();
      const adduserapi = window.harestec_service + 'add_user_API'
      const res = await fetch(adduserapi, settings);
      console.log(res);
      const apidata = await res.json();
      const fdata = apidata.data;
      localStorage.setItem('client', JSON.stringify(fdata?.map((i) => i)));
      const clientdatastr = localStorage.getItem('client');
      if (clientdatastr != 'undefined') {
        const clientdata = JSON.parse(clientdatastr);
        activitydata();
        if (clientdata[0].user_type_id == '3') {
          if(type == "nonbuy"){
            window.open('/resources', '_self');
          }else{
            window.open(`/paymentpage?id=${id}&amount=${amount}&type=${typeofmain}`, '_self');
          }
        } else {
          document.getElementById('errormsg').style.display = "flex"
        }
      } else {
        document.getElementById('errormsg').style.display = "flex"
      }
    } catch (error) {
      console.log(error);
    }
  };
  const errorMessage = (error) => {
    console.log(error);
  };

// log out function to log the user out of google and set the profile array to null
const logOut = () => {
    googleLogout();
    setProfile(null);
};

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Log in to Harvestec Learning Portal
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <GoogleLogin cookiePolicy={'single_host_origin'} buttonText="Sign in with Google" onSuccess={responseMessage} onError={errorMessage} style={{margin: "10px 0"}}/>
          <MDBox my={3} style={{ margin: "2% 0" }} component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="email" label="Email" fullWidth id="inputemail" />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Password" fullWidth id="inputpassword" />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={signIn}>
                sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
