import * as React from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import { useState, useEffect } from "react";
import 'react-awesome-slider/dist/styles.css';
import { useMediaQuery } from 'react-responsive'

const AutoplaySlider = withAutoplay(AwesomeSlider);

function Testimonial() {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })

    const [dataf, setApiData] = useState();
    const [pic, setPic] = useState([])

    const gettesyimonialdata = async () => {
        const testimonalapihandler = window.harestec_service + `testimonials`
        const settings = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        };
        try {
            const res = await fetch(testimonalapihandler, settings);
            const apidata = await res.json();
            const fdata = apidata.data;
            setApiData(fdata?.map((i) => i));
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        gettesyimonialdata();
    }, []);

    return (
        <>
            <MDBox style={{ background: '#433C3C' }}>
                <MDBox py={2} style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <MDBox style={{ width: "80%", display: "flex", justifyContent: "center", alignItems: "center", margin: "1rem 0" }}>
                        <MDBox>
                            <MDTypography style={{ fontSize: "20px", fontWeight: "600", lineHeight: "38.73px", color: "#fff", fontFamily: "Inter" }}>
                                Endorsements from the best scientific and business fraternity
                            </MDTypography>
                            <MDBox style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                <MDBox style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "48%" }}>
                                    <MDBox style={{ width: "100%", borderBottom: "2px solid #E0AA3E" }}></MDBox>
                                </MDBox>
                                <p class="diamond-icon" style={{ fontSize: "24px", color: "#E0AA3E", margin: "0px 0" }}>♦</p>
                                <MDBox style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "48%" }}>
                                    <MDBox style={{ width: "100%", borderBottom: "2px solid #E0AA3E" }}></MDBox>
                                </MDBox>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </MDBox>
                <AwesomeSlider bullets={false} style={{ height: `${isDesktopOrLaptop == true ? '300px' : '700px'}`, paddingBottom: "45px" }}>
                    {dataf?.map((elem) => (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", background: '#433C3C' }}>
                            <MDBox mx={5} style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#433C3C" }}>
                                <MDBox py={3} style={{ width: "80%", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#2B5F47", borderRadius: "16px" }}>
                                    <MDBox style={{ width: "90%", display: `${isDesktopOrLaptop == true ? 'flex' : 'block'}`, justifyContent: "center", alignItems: "center" }}>
                                        <MDBox style={{ width: `${isDesktopOrLaptop == true ? '40%' : '100%'}`, height: "100%", display: "flex", justifyContent: "start", alignItems: "start" }}>
                                            <MDBox mx={6} style={{ width: "257.6px", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <img src={'/' + `${elem.image != null ? elem.image : 'avtar3.jpeg'}`} style={{ height: "144px", width: "144px", zIndex: "10" }} />
                                            </MDBox>
                                        </MDBox>
                                        <MDBox style={{ width: `${isDesktopOrLaptop == true ? '60%' : '100%'}`, height: "100%", display: "flex", justifyContent: "start", alignItems: "center" }}>
                                            <MDBox style={{ width: "100%", height: "40%" }}>
                                                <MDBox style={{ width: "100%", display: "flex", justifyContent: "start", alignItems: "center" }}>
                                                    <img src='/Vector.png' />
                                                </MDBox>
                                                <MDBox style={{ width: "100%" }}>
                                                    <MDTypography mx={4} my={1} style={{ fontSize: "14px", fontWeight: "400", lineHeight: "23px", color: "#FFFFFF", fontFamily: "Inter" }}>
                                                        {elem.discription.substring(0, 150) + '...'}
                                                    </MDTypography>
                                                </MDBox>
                                                <MDBox style={{ width: "100%", display: "flex", justifyContent: "end", alignItems: "center" }}>
                                                    <img src='/Vectorl.png' />
                                                </MDBox>
                                                <MDTypography mx={4} my={1} style={{ fontSize: "16px", fontWeight: "400", lineHeight: "29.05px", color: "#FFFFFF", fontFamily: "Inter" }}>
                                                    {elem.name}
                                                </MDTypography>
                                                <MDTypography mx={4} my={1} style={{ fontSize: "14px", fontWeight: "400", lineHeight: "29.05px", color: "#FFFFFF", fontFamily: "Inter" }}>
                                                    {elem.designation}
                                                </MDTypography>
                                            </MDBox>
                                        </MDBox>
                                    </MDBox>
                                </MDBox>
                            </MDBox>
                        </div>
                    ))}
                </AwesomeSlider>
            </MDBox>
        </>
    )
}

export default Testimonial;