import * as React from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMediaQuery } from 'react-responsive'

function Footercus() {

    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})

    return (
        <>
            <MDBox style={{ width: "100%", backgroundColor: "#2B5F47", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <MDBox my={2} style={{ width: "80%", height: "50%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <MDBox style={{ width: "40%", height: "100%" }}>
                        <MDBox mb={2} style={{ width: "100%", display: "flex", justifyContent: "start", alignItems: "center" }}>
                            <img src='/logo.png' style={{ borderRadius: "10px", height: "50px" }} />
                        </MDBox>
                        <MDBox mb={1} style={{ width: "100%", display: "flex", justifyContent: "start", alignItems: "center" }}>
                            <MDTypography style={{ fontSize: "11px", fontWeight: "400", lineHeight: "24.2px", color: "#fff", fontFamily: "Inter" }}>
                                Privacy Policy | Terms & Conditions
                            </MDTypography>
                        </MDBox>
                        <MDBox style={{ width: "100%", display: "flex", justifyContent: "start", alignItems: "center" }}>
                            <MDTypography style={{ fontSize: "11px", fontWeight: "400", lineHeight: "24.2px", color: "#fff", fontFamily: "Inter" }}>
                                Copyright @ 2023 Harvesto. All rights reserved
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                    <MDBox ml={6} style={{ width: "50%", height: "50%" }}>
                        <MDBox mb={1} style={{ width: "100%", display: "flex", justifyContent: "start", alignItems: "center" }}>
                            <MDTypography style={{ fontSize: "12px", fontWeight: "600", lineHeight: "24px", color: "#fff", fontFamily: "Inter" }}>
                                Quick Links
                            </MDTypography>
                        </MDBox>
                        <MDBox mb={1} style={{ width: "100%", display: `${isDesktopOrLaptop == true ? 'flex' : 'block'}`, justifyContent: "center", alignItems: "center" }}>
                            <MDBox mb={1} style={{ width: "100%", display: "flex", justifyContent: "start", alignItems: "center" }}>
                                <MDTypography style={{ fontSize: "11px", fontWeight: "500", lineHeight: "24px", color: "#fff", fontFamily: "Inter", cursor: "pointer" }}>
                                    Resources
                                </MDTypography>
                            </MDBox>
                            <MDBox mb={1} style={{ width: "100%", display: "flex", justifyContent: "start", alignItems: "center" }}>
                                <MDTypography style={{ fontSize: "11px", fontWeight: "500", lineHeight: "24px", color: "#fff", fontFamily: "Inter", cursor: "pointer" }}>
                                    FAQ’s
                                </MDTypography>
                            </MDBox>
                            <MDBox style={{ width: "100%", display: "flex", justifyContent: "start", alignItems: "center" }}>
                                <MDTypography style={{ fontSize: "11px", fontWeight: "500", lineHeight: "24px", color: "#fff", fontFamily: "Inter", cursor: "pointer" }}>
                                    About author
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </MDBox>
        </>
    )
}

export default Footercus;