import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import { useState, useEffect } from "react";
import { createTheme } from "@mui/material/styles";
import DataTable from "examples/Tables/DataTable";
import useRazorpay from "react-razorpay";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import authorsTableData from "layouts/transaction/data/authorsTableData";
import Footer from "examples/Footer";

const mystyle = {
  fontSize: "12px"
};

const theme = createTheme({
  //v5.0.0
  typography: {
    body1:{
      fontSize: [12, "!important"]
  },
    body2: {
        fontSize: [10, "!important"]
    }
  },
});

function Transaction() {

  const { columns, rows } = authorsTableData();
  function adduser() {
    window.location.replace(window.urlmain + `edituser?type=users`);

  }
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h1"  color="white" style = {{fontSize: "12px"}}>
                  All Transactions
                </MDTypography>
              </MDBox>
              <MDBox pt={3} style={{ margin: "auto" }}>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Transaction;
