import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import "jodit/build/jodit.min.css";
import JoditEditor from 'jodit-react';
import {AlertServices} from './../../services/alert.service'

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDInput from "components/MDInput";
// import Swal from 'sweetalert2'

const styles = {
  Appheader: {
    backgroundColor: "#ffffff",
    maxHeight: "2vh",
    display: "flex",
    flexDirection: "column",
    fontSize: "calc(10px + 1vmin)",
    color: "black",
    marginBottom: "5vh",
    textAlign: "center",
    padding: "12px",
  },
  App1: {
    backgroundColor: "#ffffff",
    height: "190px",
    display: "flex",
    flexDirection: "column",
    fontSize: "calc(10px + 1vmin)",
    color: "black",
    marginBottom: "5vh",
    textAlign: "center",
    padding: "50px",
    width: "100%",
    margin: "3% 0 3% 0",
  },
  App: {
    minHeight: "53vh",
    backgroundColor: "#ffffff",
  },
  App2: {
    height: "150px",
    width: "150px",
    margin: "20px"
  }
};



function edit() {
  const queryParameters = new URLSearchParams(window.location.search);
  const divison = queryParameters.get("divison");
  const type = queryParameters.get("type");
  const id = queryParameters.get("id");
  const apiwork = queryParameters.get("apiwork");
  const [getIdBasedData, setGetIdBasedData] = useState("");
  const [getParentBasedData, setGetParentBasedData] = useState([]);
  const [getParent, setParent] = useState("");
  // const [getEditorElemeny, setEditorElement] = useState();
  // const editor = useRef(null);
  const [content, setContent] = useState('');
  const [image, setImage] = useState();
  const [epub, setEpub] = useState();
  const [getAllImage, setAllImage] = useState();

  const config =
  {
    placeholder: 'Start typings...'
  }
    ;

  // workinf on work base api

  const fetchApiData1 = async (url) => {
    console.log(url, 'line no 86');
    const querryname = document.getElementById("querryname").value;
    const descriptionshort = document.getElementById("descriptionshort").value;
    const amountinr = document.getElementById("amountinr").value;
    const amountusd = document.getElementById("amountusd").value;
    if (querryname == '') {
      AlertServices({
        title : 'opps !' ,
        text : "All fields are required" ,
        icon : 'warning' ,
        apiCall : "N",
        apiHandler : window.curd_service + `?key_params=topics_id_delete&type=delete&id=${id}`,
        type : 'delete',
        dismissMassage : 'Your imaginary data is safe :)'
      }) 
    } else if (descriptionshort == '') {
      Swal.fire('Oops...', 'short description field is empty!', 'error')
    } else if (amountinr == '') {
      Swal.fire('Oops...', 'amount field is empty!', 'error')
    }else if (amountusd == '') {
      Swal.fire('Oops...', 'amount field is empty!', 'error')
    } else if (epub.name == '') {
      Swal.fire('Oops...', 'book is required!', 'error')
    } else if (image.name == '') {
      Swal.fire('Oops...', 'image is required!', 'error')
    } else {
      const data = {
        "id": id,
        "name": querryname,
        "desc": epub.name,
        "descshort": descriptionshort,
        "amount_inr": amountinr,
        "amount_usd": amountusd,
        "image_name": image.name,
        "added_by": "1",
        "parent_id": getParent,
      }
      console.log(data);
      const settings = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
      };
      try {
        const res = await fetch(url, settings);
        await res.json();
        if (divison == "edittopic") {
          window.location.replace('/topics');
        } else if (divison == "editchapter") {
          window.location.replace('/chapter');
        } else if (divison == "editsection") {
          window.location.replace('/Section');
        }
        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    }

  };

  const fetchApiData = async (url) => {
    const querryname = document.getElementById("querryname").value;
    const descriptionshort = document.getElementById("descriptionshort").value;
    const amountinr = document.getElementById("amountinr").value;
    const amountusd = document.getElementById("amountusd").value;
    if (querryname == '') {
      Swal.fire('Oops...', 'name field is empty!', 'error')
    } else if (descriptionshort == '') {
      Swal.fire('Oops...', 'short description field is empty!', 'error')
    } else if (amountinr == '') {
      Swal.fire('Oops...', 'amount field is empty!', 'error')
    }else if (amountusd == '') {
      Swal.fire('Oops...', 'amount field is empty!', 'error')
    } else if (epub.name == '') {
      Swal.fire('Oops...', 'book is required!', 'error')
    } else if (image.name == '') {
      Swal.fire('Oops...', 'image is required!', 'error')
    } else {
      const data = {
        "id": id,
        "name": querryname,
        "desc": epub.name,
        "descshort": descriptionshort,
        "amount_inr": amountinr,
        "amount_usd": amountusd,
        "image_name": image.name,
        "added_by": "1",
        "parent_id": getParent,
      }
      console.log(data);
      const settings = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
      };


      try {
        const res = await fetch(url, settings);
        await res.json();
        if (divison == "insert_into_topic") {
          window.location.replace('/topics');
        } else if (divison == "insert_into_chapter") {
          window.location.replace('/chapter');
        } else if (divison == "insert_into_section") {
          window.location.replace('/Section');
        }
      } catch (error) {
        console.log(error);
      }
    }

  };

  // working on addition

  function adddata() {
    console.log("line no 73");
    const api = window.harestec_service + `${apiwork}`;
    fetchApiData(api);
  }

  // working on update

  function editdata() {
    console.log("line no 73");
    const api = window.harestec_service + `${apiwork}`;
    fetchApiData1(api);
  }

  // calling function on the basis of worktype

  function onsubmitdata() {
    { type == "insert" ? adddata() : editdata() }
  }


  // get data on the basis of id 

  const fetcheditApiData = async () => {
    let quarypara = '';
    const typeofwork = 'edit';
    {
      divison == "edittopic" ?
        quarypara = 'topics_id' :
        divison == "editchapter" ?
          quarypara = 'chapter_id' :
          divison == "editsection" ?
            quarypara = 'section_id' : quarypara = 'question_id'
    }
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await fetch(window.curd_service + `?key_params=${quarypara}&type=${typeofwork}&id=${id}`, settings);
      const apidata = await res.json();
      const fdata = apidata.data;
      console.log(fdata, 'line no 254');
      setGetIdBasedData(fdata);
      {
        divison == "edittopic" ?
          (document.getElementById('querryname').value = fdata[0].name, setContent(fdata[0].descs), document.getElementById('descriptionshort').value = fdata[0].descshort, document.getElementById('amountinr').value = fdata[0].amount_inr, document.getElementById('amountusd').value = fdata[0].amount_usd) :
          divison == "editchapter" ?
            (document.getElementById('querryname').value = fdata[0].name, setContent(fdata[0].descs),setParent(fdata[0].parent_id), document.getElementById('descriptionshort').value = fdata[0].descshort, document.getElementById('amountinr').value = fdata[0].amount_inr, document.getElementById('amountusd').value = fdata[0].amount_usd, console.log(amount_inr, amount_usd, 'line no 260')) :
            divison == "editsection" ?
              (document.getElementById('querryname').value = fdata[0].name, setContent(fdata[0].descs), document.getElementById('descriptionshort').value = fdata[0].descshort, document.getElementById('amountinr').value = fdata[0].amount_inr, document.getElementById('amountusd').value = fdata[0].amount_usd) :
              (document.getElementById('querryname').value = fdata[0].question_name, document.getElementById('querrydescanswer').innerHTML = getIdBasedData[0]?.answer)
      }
    } catch (error) {
      console.log(error);
    }
  }

  // get data of parent

  const fetchedparentApiData = async () => {
    let parentquerrydata = '';
    const typeofwork = "select"
    {
      divison == "insert_into_chapter" || divison == "editchapter" ?
        parentquerrydata = 'topics' :
        divison == "insert_into_section" || divison == "editsection" ?
          parentquerrydata = 'chapter' : parentquerrydata = 'section'
    }
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await fetch(window.curd_service + `?key_params=${parentquerrydata}&type=${typeofwork}`, settings);
      const apidata = await res.json();
      const fdata = apidata.data;
      setGetParentBasedData(fdata?.map((i) => i));
      // editorelem.current = fdata[0].descs
    } catch (error) {
      console.log(error);
    }
  }

  // using of hooks for getting data

  useEffect(() => {
    { id != "0" ? (fetcheditApiData(), fetchedparentApiData()) : (fetchedparentApiData()) };
  }, []);

  // getting parent

  const handleChangeselect = (e) => {
    const parentid = e.target.value;
    setParent(parentid);
  }

  // upload image

  const uploadimage = async () => {
    console.log('hii');
    const imageupload = window.harestec_service + 'image_upload';
    const formdata = new FormData();
    console.log(image)
    formdata.append('file', image, image.name);
    formdata.append('name', 'demo');
    console.log(formdata);
    let response = await axios.post(imageupload, formdata);
    console.log(response);
  }

  const uploadepub = async () => {
    const epubupload = window.harestec_service + 'book_upload';
    const formdata = new FormData();
    console.log(epub)
    formdata.append('file', epub, epub.name);
    formdata.append('name', 'demo');
    console.log(formdata);
    let response = await axios.post(epubupload, formdata);
    console.log(response);
  }

  const handleimage = (e) => {
    const getimg = e.target.files[0];
    console.log(getimg);
    setImage(getimg);
    document.getElementById('uploadpic').hidden = false;
  }

  const handleepub = (e) => {
    const getepub = e.target.files[0];
    console.log(getepub);
    setEpub(getepub);
    document.getElementById('uploadepub').hidden = false;
  }


 

  // using of hooks for getting data

  useEffect(() => {
    console.log("run line num 251");
    // getimageapi();
    { id != "0" ? (fetcheditApiData(), fetchedparentApiData()) : (fetchedparentApiData()) };
  }, []);

  // rendering start
  console.log(getAllImage);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDTypography variant="h4" fontWeight="medium" color="dark" mt={1} my={2}>
        {divison == "insert_into_topic" || divison == "edittopic" ?
          "Topic : " :
          divison == "insert_into_chapter" || divison == "editchapter" ?
            "Chapter : " :
            divison == "insert_into_section" || divison == "editsection" ?
              "Section : " : "Question"
        }
      </MDTypography>
      {divison == "insert_into_topic" || divison == "edittopic" ? (
        ''
      ) : (
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            {divison == "insert_into_chapter" || divison == "editchapter" ?
              "Select topics : " :
              divison == "insert_into_section" || divison == "editsection" ?
                "Select chapter : " : "Select section"
            }
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            value={getParent}
            label="Age"
            onChange={handleChangeselect}
            style={{ height: "45px", marginBottom: "15px" }}
          >
            {getParentBasedData.map((e, index) => (
              <MenuItem {...getParent == e.id ? 'selected' : ''} key={index} value={e.id}>{e.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <MDBox mb={2}>
        <MDInput type="text" label={type != "insert" ? "" : "Name"} fullWidth id="querryname" required/>
      </MDBox>
      <MDBox mb={2}>
        <MDInput type="text" label={type != "insert" ? "" : "Description"} fullWidth id="descriptionshort" required/>
      </MDBox>
      <MDBox mb={2}>
        <MDInput type="text" label={type != "insert" ? "" : "charges inr"} fullWidth id="amountinr" required/>
      </MDBox>
      <MDBox mb={2}>
        <MDInput type="text" label={type != "insert" ? "" : "charges usd"} fullWidth id="amountusd" required/>
      </MDBox>
      <MDBox mb={2}>
        <MDTypography variant="h4" fontWeight="medium" color="dark" mt={1} my={2}>
          Upload Image:
        </MDTypography>
        <form className="search-wrapper cf" style={{ display: "flex" }}>
          <MDInput type="file" label="" fullWidth onChange={handleimage} id="imagename" name="file" style={{ Width: "80%" }} required accept="image/jpg, image/png"/>
          <Button type="submit" hidden onClick={(event) => { event.preventDefault(); uploadimage(); }} style={{ marginLeft: "10px", color: "#ffffff", float: "right", marginRight: "20px", marginBottom: "30px", backgroundColor: "#3bad21" }} id="uploadpic">Upload</Button>
        </form>
      </MDBox>
      <MDBox mb={2}>
        <MDTypography variant="h4" fontWeight="medium" color="dark" mt={1} my={2}>
          Upload Books:
        </MDTypography>
        <form className="search-wrapper cf" style={{ display: "flex" }}>
          <MDInput type="file" label="" fullWidth onChange={handleepub} id="imagename" name="file" style={{ Width: "80%" }} required accept=".epub"/>
          <Button type="submit" hidden onClick={(event) => { event.preventDefault(); uploadepub(); }} style={{ marginLeft: "10px", color: "#ffffff", float: "right", marginRight: "20px", marginBottom: "30px", backgroundColor: "#3bad21" }} id="uploadepub">Upload</Button>
        </form>
      </MDBox>
      <Button type="submit" variant="contained" onClick={(event) => {event.preventDefault(); onsubmitdata()}} style={{ color: "#ffffff", float: "right", marginRight: "20px", marginBottom: "25px", backgroundColor: "#3bad21" }}>Submit</Button>
      <Footer />
    </DashboardLayout>
  );
}

export default edit;






