import * as React from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState, useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import image from './formbg.jpg'
import { useMediaQuery } from 'react-responsive'

export default function Videocallassitance() {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
    return (
        <>
            <MDBox my={2} style={{ display: `${isDesktopOrLaptop == true ? "flex" : "block"}`, alignItems: "center", justifyContent: "center", background: `url(${image})`, backgroundRepeat: "no-repeat", backgroundSize: "100% 100%", height: "450px" }}>
                <MDBox m={3} style={{ display: "flex", alignItems: "center", justifyContent: "center", width: `${isDesktopOrLaptop == true ? "50%" : "90%"}` }}>
                    <MDBox style={{borderBottom : "3px solid black"}}>
                        <MDTypography style={{ color: "#fff", fontSize: "36px", fontWeight: "700" }}>Video Assistance</MDTypography>
                    </MDBox>
                </MDBox>
                <MDBox m={3} style={{ display: "flex", alignItems: "center", justifyContent: "center", width: `${isDesktopOrLaptop == true ? "50%" : "90%"}` }}>
                    <MDTypography style={{ color: "#fff", fontSize: "16px", fontWeight: "600" }}> Embark on a journey of technical mastery. Schedule your personalized consultation now to delve deep into the nuances of Food Grain Bulk Storage and Rice Technology. Our seasoned experts stand ready to provide comprehensive insights, address intricate technical queries, and guide you towards optimized solutions. Stay at the forefront of industry knowledge – secure your session today and enhance your proficiency in Food Grain Management and Rice Technology.</MDTypography>
                </MDBox>
            </MDBox>
        </>
    )
}