import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import "jodit/build/jodit.min.css";
import JoditEditor from 'jodit-react';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDInput from "components/MDInput";

const styles = {
  Appheader: {
    backgroundColor: "#ffffff",
    maxHeight: "2vh",
    display: "flex",
    flexDirection: "column",
    fontSize: "calc(10px + 1vmin)",
    color: "black",
    marginBottom: "5vh",
    textAlign: "center",
    padding: "12px",
  },
  App1: {
    backgroundColor: "#ffffff",
    height: "190px",
    display: "flex",
    flexDirection: "column",
    fontSize: "calc(10px + 1vmin)",
    color: "black",
    marginBottom: "5vh",
    textAlign: "center",
    padding: "50px",
    width: "100%",
    margin: "3% 0 3% 0",
  },
  App: {
    minHeight: "53vh",
    backgroundColor: "#ffffff",
  },
  App2: {
    height: "150px",
    width: "150px",
    margin: "20px"
  }
};



function Editmedia() {
  const queryParameters = new URLSearchParams(window.location.search);
  const type = queryParameters.get("type");
  const [image, setImage] = useState();
  const [getType, setType] = useState('');

  // workinf on work base api

  const fetchApiData = async (url) => {
    const querryname = document.getElementById("querryname").value;

    const data = {
      "name": querryname,
      "media_name": image.name,
      "type": getType,
      "added_by": "1",
    }
    console.log(data);
    const settings = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await fetch(url, settings);
      await res.json();
      window.location.replace(window.urlmain + `${type}`);
    } catch (error) {
      console.log(error);
    }
  };
 
  // calling function on the basis of worktype

  function onsubmitdata() {
    const api = window.harestec_service + `addtbl_media`;
    fetchApiData(api);
  }

 

  // getting parent

  const handleChangeselect = (e) => {
    const type = e.target.value;
    console.log(type)
    setType(type);
  }

  // upload image

  const uploadimage = async () => {
    const imageupload = window.harestec_service + 'image_upload';
    const formdata = new FormData();
    console.log(image)
    formdata.append('file', image, image.name);
    formdata.append('name', 'demo');
    console.log(formdata);
    let response = await axios.post(imageupload, formdata);
    console.log(response);
  }

  const handleimage = (e) => {
    if(getType == ''){
      Swal.fire('Oops...', 'Media type is required!', 'error')
    }else{
      const getimg = e.target.files[0];
      console.log(getimg);
      setImage(getimg);
      document.getElementById('uploadfiles').disabled = false;
    }
    
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDTypography variant="h4" fontWeight="medium" color="dark" mt={1} my={2}>
        Media
      </MDTypography>
      <MDBox mb={2}>
        <MDInput type="text" label="Name" fullWidth id="querryname" />
      </MDBox>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">
          Select type
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          value={getType}
          label="Age"
          onChange={handleChangeselect}
          style={{ height: "45px", marginBottom: "15px" }}
        >
          <MenuItem value="Banner">Banner</MenuItem>
          <MenuItem value="Gallery">Gallery</MenuItem>
        </Select>
      </FormControl>
      <MDBox mb={2}>
        <form className="search-wrapper cf" style={{ display: "flex" }}>
          <MDInput type="file" label="file" fullWidth onChange={handleimage} name="file" style={{ Width: "80%" }} required accept="image/jpg, image/png"/>
          <Button type="submit" disabled onClick={(event) => { event.preventDefault(); uploadimage(); }} style={{ marginLeft: "10px", color: "#ffffff", float: "right", marginRight: "20px", marginBottom: "30px", backgroundColor: "#3bad21" }} id="uploadfiles">Upload</Button>
        </form>
      </MDBox>
      <Button variant="contained" onClick={onsubmitdata} style={{ color: "#ffffff", float: "right", marginRight: "20px", marginBottom: "25px", backgroundColor: "#3bad21" }}>Submit</Button>
      <Footer />
    </DashboardLayout>
  );
}

export default Editmedia;







// const uploadimage = async (event) => {
//   event.preventDefault();
//   const imageupload = 'http://localhost:3000/public'
//   const formdata = new FormData();
//   console.log(image)
//   formdata.append('myFile', image, image.name);
//   formdata.append('name', 'demo');
//   console.log(formdata);
//   let response = await axios.post(imageupload, formdata);
//   console.log(response);
// }
// const state = {
//   file: null,
//   base64URL: ""
// };

// const getBase64 = file => {
//   return new Promise(resolve => {
//     let fileInfo;
//     let baseURL = "";
//     // Make new FileReader
//     let reader = new FileReader();

//     // Convert the file to base64 text
//     reader.readAsDataURL(file);

//     // on reader load somthing...
//     reader.onload = () => {
//       // Make a fileInfo Object
//       console.log("Called", reader);
//       baseURL = reader.result;
//       console.log(baseURL);
//       setBaseUrl(baseURL);
//       resolve(baseURL);
//     };
//     console.log(fileInfo);
//   });
// };
// function getImage(e) {
//   console.log("changed");
//   console.log(e.target.files[0]);
//   setImage(e.target.files[0])
//   let { file } = state;

//   file = e.target.files[0];

//   getBase64(file)
//     .then(result => {
//       file["base64"] = result;
//       console.log("File Is", file);
//       this.setState({
//         base64URL: result,
//         file
//       });
//     })
//     .catch(err => {
//       console.log(err);
//     });
// }
// const fetchTopicApiData = async (url) => {
//   const settings = {
//     method: "POST",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//     },
//   };
//   try {
//     const res = await fetch(url, settings);
//     const apidata = await res.json();
//     const fdata = apidata.data;
//     setTopicData(fdata);
//   } catch (error) {
//     console.log(error);
//   }
// }
// useEffect(() => {
//   fetchTopicApiData(topicapi);
// }, []);
// console.log(topicdata)
// const handleChange = (e) => {
//   const topic_id = e.target.value;
//   setAge(topic_id);
// }
// const [editorState, setEditorState] = useState(
//   () => EditorState.createEmpty(),
// );