import Button from "react-bootstrap/Button";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useState, useEffect } from "react";
import useRazorpay from "react-razorpay";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";



function Detchap() {
  const Razorpay = useRazorpay();
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");
  const [dataf, setApiData] = useState();
  const api = window.curd_service + `?key_params=chapter_id&type=edit&id=${id}`;
  console.log(api);

  const settings = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    }
  }

  const fetchApiData = async (url) => {
    try {
      const res = await fetch(url, settings);
      const apidata = await res.json();
      const fdata = apidata.data;
      console.log(fdata)
      setApiData(fdata?.map((i) => i));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchApiData(api);
  }, []);

  // payment gateway resp function

  const respfunction = async (id, payment_id, status, msg) => {
    const data = {
      "pay_id": payment_id,
      "status": status,
      "error_msg": msg
    }
    const settings = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    const api = window.harestec_service + `updateuser_paymt_txnsAPI/${id}`;
    await fetch(api, settings)
  }

  // main payment gateway

  const buy = async (id, amount, type) => {
    if (localStorage.getItem('client') == null) {
      const login_type = "buy_login"
      window.location.replace(window.urlmain + `authentication/sign-inclient?id=${id}&amount=${amount}&type=${type}&login_type=${login_type}&previous=${finalprevious}`)
    } else {
      const userlogin = localStorage.getItem('client');
      const userlogindata = JSON.parse(userlogin)
      const user_id = userlogindata[0].id;
      const fdataarray = amount.split('/');
      console.log(fdataarray[0])
      const famtarray = fdataarray[0].split(',')
      const amtstring = famtarray[0] + famtarray[1];
      console.log(amtstring);
      const famount = Number(amtstring) * 100;
      console.log(famount)
      const data = {
        "user_id": user_id,
        "type_id": id,
        "amt": amount,
        "type": type
      }

      const settings = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      const api = window.harestec_service + 'adduser_paymt_txnsAPI';
      const res = await fetch(api, settings)
      const fdata = await res.json();
      const fdataid = fdata.data[0].id;
      const reciept = 'receipt_order_' + id + 'cli' + user_id;
      if (fdata.message == "Added Successfully") {
        const options = {
          key: "rzp_live_hmiII6CslKr36A", // Enter the Key ID generated from the Dashboard
          amount: famount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: "INR",
          name: "Proeffico solutions private limited",
          description: "Test Transaction",
          image: "logo.jpg",
          receipt: reciept, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
          handler: function (response) {
            alert(response.razorpay_payment_id);
            alert(response.razorpay_order_id);
            alert(response.razorpay_signature);
            respfunction(fdataid, response.razorpay_payment_id, 'APPROVED', response.razorpay_order_id)
          },
          prefill: {
            name: "Piyush Garg",
            email: "youremail@example.com",
            contact: "9999999999",
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };

        const rzp1 = new Razorpay(options);

        rzp1.on("payment.failed", function (response) {
          alert(response.error.code);
          alert(response.error.description);
          alert(response.error.source);
          alert(response.error.step);
          alert(response.error.reason);
          alert(response.error.metadata.order_id);
          alert(response.error.metadata.payment_id);
          respfunction(fdataid, response.error.metadata.payment_id, 'DECLINED', response.error.description)
        });

        rzp1.open();
      }
    }
  }
  console.log(dataf)
  return (
    <div style={{ width: "100%" }}>
      {dataf?.map((elem) => (
        <div>
          <div style={{ width: "100%" }}>
            <Button onClick={(e) => { buy(id, elem.amount, 'chap') }} className="my-3 mx-5" variant="primary" style={{ float: "right" }}>Buy</Button>
          </div>
          <div className="mx-5 my-3" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ width: "50%", height: "500px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img alt="chap_name" src={'https://harvestec.in/image/'+elem.image_name} style={{ width: "100%", height: "300px", borderRadius: "12px" }} />
            </div>
            <div style={{ width: "50%", height: "500px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div style={{ width: "80%", height: "80%" }}>
                <MDTypography className="my-3" variant="h1" color="dark" style={{ fontSize: "24px" }}>
                  {elem.name}
                </MDTypography>
                <MDTypography className="my-3" variant="p" color="dark" style={{ fontSize: "13px", fontWeight: "400" }}>
                  {elem.descshort.substring(0, 350)}
                </MDTypography>
                <MDTypography className="my-3" variant="h6" color="dark" style={{ fontSize: "12px" }}>
                  MRP - {elem.amount}
                </MDTypography>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Detchap;
