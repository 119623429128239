import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { useState, useEffect } from "react";
import { sha1 } from "crypto-hash"

function Modalbssignin({changestatemodal}) {
  const [show, setShow] = useState('hide');
  const changemodalfunction = () => {
    show == 'show' ? setShow('hide') : setShow('show');
    changestatemodal(show);
  }

  const fetchApiData = async (url) => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await fetch(url, settings);
      const apidata = await res.json();
      const fdata = apidata.data;
      window.open('/resources', '_self');
    } catch (error) {
      alert(error);
    }
  };
  function signUp() {
    const fname = document.getElementById('inputFeildName').value
    const email = document.getElementById('inputFeildEmail').value
    const cpassword = document.getElementById('inputFeildPass').value
    const inputFeildmobile = document.getElementById('inputFeildmobile').value
    const inputFeildOrganisation = document.getElementById('inputFeildOrganisation').value
    const inputFeildcountry = document.getElementById('inputFeildcountry').value
    let itemarray = [];
    const hashPass = async () => {
      const password = await sha1(cpassword);
      itemarray.push(fname, email, password, null, inputFeildmobile, 3, null, null, null, null, null, inputFeildOrganisation, inputFeildcountry);
      const api = window.curd_service + `?key_params=add_user&type=insert&item=${itemarray}`
      fetchApiData(api);
    }
    hashPass();
  }
  return (
    <div
      className="modal show"
      style={{ display: 'block', position: 'fixed' }}
    >
      <Modal.Dialog style={{color: "#fff"}}>
        <Modal.Header closeButton onClick={changemodalfunction}>
        </Modal.Header>
        <MDBox style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>
          <MDBox style={{ width: "80%"}}>
            <MDBox my={1} style={{display: "flex", justifyContent: "start", alignItems: "center", width: "100%"}}>
              <MDTypography style={{fontSize: '20px', fontWeight: "500", lineHeight: "24.2px", color: "#1E1B1B" }}>Sign up to your account</MDTypography>
            </MDBox>
            <MDBox my={1} style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>
              <MDInput type="text" label="Name" fullWidth id="inputFeildName" required/>
            </MDBox>
            <MDBox my={1} style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>
              <MDInput type="email" label="Email" fullWidth id="inputFeildEmail" required/>
            </MDBox>
            <MDBox my={1} style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>
              <MDInput type="text" label="Mobile" fullWidth id="inputFeildmobile" required/>
            </MDBox>
            <MDBox my={1} style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>
              <MDInput type="text" label="Organisation" fullWidth id="inputFeildOrganisation" required/>
            </MDBox>
            <MDBox my={1} style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>
              <MDInput type="text" label="Country" fullWidth id="inputFeildcountry" required/>
            </MDBox>
            <MDBox my={1} style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>
              <MDInput type="password" label="Password" fullWidth id="inputFeildPass" required/>
            </MDBox>
            <MDBox my={2} mb={3} style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "56px", borderRadius: "48px", backgroundColor: "#2B5F47", color: "#fff" }} >
              <MDTypography mx={2} style={{fontSize: '16px', fontWeight: "500", lineHeight: "23px", color: "#fff",cursor: "pointer" }} onClick={(e) => {e.preventDefault();signUp();}}>Register</MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Modal.Dialog>
    </div>
  );
}

export default Modalbssignin;