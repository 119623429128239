import Box from '@mui/material/Box';
import { useState, useEffect } from "react";
import Typography from '@mui/material/Typography';
import Button from 'react-bootstrap/Button';
import useRazorpay from "react-razorpay";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import ResourcesNav from "components/ResourcesNav";
import { useMediaQuery } from 'react-responsive';

function Paymentpage() {
    const Razorpay = useRazorpay();
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
    const queryParameters = new URLSearchParams(window.location.search);
    const id = queryParameters.get("id");
    const amount = queryParameters.get("amount");
    const userlogin = localStorage.getItem('client');
    const type = queryParameters.get("type");
    const userlogindata = JSON.parse(userlogin)
    const user_id = userlogindata[0].id;
    const userstr = localStorage.getItem('client');
    const user = JSON.parse(userstr);
    const email = user[0].email
    const apihandleruser = window.harestec_service + `User_data?user_id=${user_id}`
    const apihandlerpurdata = window.harestec_service + `metedataInformationAPI?id=${id}&type=${type}`
    const [getApiData, setApiData] = useState();
    const [geyMetaData, setApiMetaData] = useState();
    const fetchApiData = async (url) => {
        const settings = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        };
        try {
            const res = await fetch(url, settings);
            const fdata = await res.json();
            const apidata = fdata.list;
            setApiData(apidata?.map((i) => i));
        } catch (error) {
            console.log(error);
        }
    };
    const fetchApimetaData = async (url) => {
        const settings = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        };
        try {
            const res = await fetch(url, settings);
            const fdata = await res.json();
            const apidata = fdata.list;
            setApiMetaData(apidata?.map((i) => i));
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchApiData(apihandleruser);
        fetchApimetaData(apihandlerpurdata);
    }, []);

    const respfunction = async (id, payment_id, status, msg, response) => {
        const data = {
            "pay_id": payment_id,
            "status": status,
            "error_msg": msg,
            "response": JSON.stringify(response)
        }
        const settings = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        };
        const api = window.harestec_service + `updateuser_paymt_txnsAPI/${id}`;
        await fetch(api, settings)
        window.open('/mytransaction')
    }

    const activitydata = async () => {
        const apihandler = window.harestec_service + 'adduser_query_areasAPI'
        const data = {
            "email": email,
            "type": "purchase"
        }
        const settings = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        };
        await fetch(apihandler, settings);
    }


    const pay = async (id, amount, type) => {
        const userlogin = localStorage.getItem('client');
        const userlogindata = JSON.parse(userlogin)
        const user_id = userlogindata[0].id;
        console.log(amount)
        const fdataarray = amount.substring(1);;
        console.log(fdataarray)
        const famount = Number(fdataarray) * 100 * 80;
        console.log(famount)
        const data = {
            "user_id": user_id,
            "type_id": id,
            "amt": amount,
            "type": type
        }

        const settings = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        };
        const api = window.harestec_service + 'adduser_paymt_txnsAPI';
        const res = await fetch(api, settings)
        const fdata = await res.json();
        console.log(fdata)
        const fdataid = fdata.data[0].id;
        console.log(fdataid)
        const reciept = 'receipt_order_' + id + 'cli' + user_id;
        console.log(reciept)
        if (fdata.message == "Added Successfully") {
            const options = {
                key: "rzp_live_qWRiu3Iz2nq5s2", // Enter the Key ID generated from the Dashboard
                amount: 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                currency: "INR",
                name: "Lotus Harvestec",
                description: "Test Transaction",
                image: "logo.png",
                receipt: reciept, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
                handler: function (response) {
                    alert('payment approved');
                    activitydata()
                    respfunction(fdataid, response.razorpay_payment_id, 'APPROVED', response.razorpay_order_id, response)
                },
                prefill: {
                    name: user[0].username,
                    email: user[0].email,
                    contact: user[0].phone,
                },
                notes: {
                    address: "Razorpay Corporate Office",
                },
                theme: {
                    color: "#3399cc",
                },
            };

            const rzp1 = new Razorpay(options);

            rzp1.on("payment.failed", function (response) {
                alert(response.error.code);
                respfunction(fdataid, response.error.metadata.payment_id, 'DECLINED', response.error.description, response)
            });

            rzp1.open();
        }
    }

    const clickhandler = () => {
        user == null ? login() : viewuser();
    }

    const login = () => {
        window.open(window.urlmain + 'authentication/sign-inclient', '_self');
    }

    const logout = () => {
        localStorage.clear();
        window.open(window.urlmain + 'client', '_self')
    }

    const viewuser = () => {
        setShow(true)
    }

    return (
        <div style={{ width: "100%", height: "100vh" }}>
            <ResourcesNav />
            <div className='my-5' style={{ width: "100%", display: 'flex', justifyContent: "center", margin: "10px 0" }}>
                <div className='w-75' style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: " 0 solid rgba(0, 0, 0, 0.125)",
                    borderRadius: "0.75rem",
                    boxShadow: "0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1), 0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06)"
                }}>
                    <Box className='w-100 py-3'>
                        <Typography component="div" variant="h5" style={{ textAlign: "center", color: "black", fontFamily: "Inter" }}>
                            <u>Transaction Summary</u>
                        </Typography>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Typography className='my-2' variant="subtitle1" color="text.secondary" component="div" style={{ fontSize: "15px", color: "black", fontFamily: "Inter" }}>
                                All your payment deatails are secured , we dont capture your credentials
                            </Typography>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-around", alignItems: "start" }}>
                            <MDBox style={{ width: "40%", display: "block", alignItems: "center", justifyContent: "center" }}>
                                {geyMetaData?.map((e, index) => (
                                    <MDBox my={2} style={{ width: "100%", display: `${isDesktopOrLaptop == true ? 'flex' : 'block'}`, alignItems: "center", justifyContent: "center" }}>
                                        <MDBox style={{ width: `${isDesktopOrLaptop == true ? '35%' : '100%'}`, height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <img src={window.photourl + 'image/' + e.image_name} style={{ width: "244.53", height: "330px", border: "1px solid #D8D8D8" }} />
                                        </MDBox>
                                    </MDBox>
                                ))}
                            </MDBox>
                            <div className='mx-4 my-2' style={{height: "330px", width: "60%"}}>
                                {geyMetaData?.map((elem) => (
                                    <div style={{height: "100%", display: "flex", alignItems: "center", justifyContent: "start"}}>
                                        <div>
                                        <Typography className='my-2' variant="subtitle1" color="text.secondary" component="div" style={{ fontSize: "12px", color: "black", fontFamily: "Inter" }}>
                                            {elem.descshort}
                                        </Typography>
                                            <tr>
                                                <td style={{ fontSize: "13px", fontWeight: "500" }}>Purchase  :</td>
                                                <td style={{ fontSize: "13px", paddingLeft: "10px" }}>{type == "chapter" ? 'Chapter' : 'Topic'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontSize: "13px", fontWeight: "500" }}>Purchase type :</td>
                                                <td style={{ fontSize: "13px", paddingLeft: "10px" }}>{elem.topic_name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontSize: "13px", fontWeight: "500" }}>Name :</td>
                                                <td style={{ fontSize: "13px", paddingLeft: "10px" }}>{elem.name}</td>
                                            </tr>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Typography className='my-2' component="div" variant="h4" style={{ color: "black" }}>
                                Amount : {amount}
                            </Typography>
                        </div>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Button style={{ fontSize: "13px" }} onClick={(e) => { e.preventDefault(); pay(id, amount, 'chap') }}>Pay now</Button>
                        </div>
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default Paymentpage;