import { useState, useEffect } from "react";
import { ReactReader } from 'react-reader';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function Mybooks() {
  const [location, setLocation] = useState(1);
  const [data, setApiData] = useState();

  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");

  const fetchApiData = async () => {
    const data = {
      "id": id
    }
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data)
    };
    try {
      const res = await fetch(window.harestec_service + "allbooksdata", settings);
      const apidata = await res.json();
      const fdata = apidata.data;
      setApiData(fdata?.map((i) => i));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchApiData();
  }, []);

  return (
    <MDBox style={{ width: "100%", height: "100%" }}>
      <MDBox style={{ width: "100%", height: "86px", backgroundColor: "#ffffff", borderBottom: "0.5px solid #2E3192", display: "flex", jsutifyContent: "center", alignItems: "center" }}>
        <MDBox style={{ width: "33%", height: "86px", display: "flex", justifyContent: "start", alignItems: "center" }}>
          <img src="/logo.png" alt="brand logo" style={{marginLeft: "20px", height: "40px"}}/>
        </MDBox>
        <MDBox style={{ width: "30%", height: "86px", display: "flex", justifyContent: "start", alignItems: "center" }}>
          {data?.map((elem) => (
            <MDTypography mx={2} style={{ fontSize: '16px', lineHeight: "36px", fontWeight: "700", color: "#151E30" }}>{elem.name}</MDTypography>
          ))}
        </MDBox>
        <MDBox style={{ width: "36%", height: "86px", display: "flex", justifyContent: "end", alignItems: "center" }}>
          <MDBox mr={4} style={{ width: "100%", display: "flex", justifyContent: "end", alignItems: "center" }}>
            <a href='/client'><MDTypography mx={2} style={{ fontSize: '14px', lineHeight: "16.41px", fontWeight: "500", color: "#151E30", cursor: "pointer" }}>Home</MDTypography></a>
            <a href='/mytransaction'><MDTypography mx={2} style={{ fontSize: '14px', lineHeight: "16.41px", fontWeight: "500", color: "#151E30", cursor: "pointer" }}>My Books</MDTypography></a>
            <img src='/profile.png' style={{ marginLeft: "10px", borderRadius: '50%' }} />
            <MDTypography mx={1} style={{ fontSize: '14px', lineHeight: "16.41px", fontWeight: "500", color: "#151E30" }}>{localStorage.getItem('client') == undefined ? 'Guest' : JSON.parse(localStorage.getItem('client'))[0].username}</MDTypography>
            <img src='/bellicon.png' style={{ marginLeft: "20px" }} />
          </MDBox>
        </MDBox>
      </MDBox>
      <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {data?.map((elem) => (
          <div style={{ height: '85vh', width: "60%" }}>
            <ReactReader
              url={`https://learning.lotusharvestec.com/${elem.descs}`}
              location={location}
              showToc={false}
              epubOptions={{
                flow: 'scrolled',
                manager: 'continuous',
              }}
            />
            <style>
              {` 
                ::-webkit-scrollbar {
                  width: 12px; /* Width of the scrollbar */
                }`
              }
            </style>
          </div>
        ))}
      </div>
    </MDBox>
  );
}

export default Mybooks;