import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import { useEffect, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

export default function data() {
  const [dataf, setApiData] = useState();
  let fdataobj = {};
  const fdataarray = [];
  const api = window.curd_service + "?key_params=get_users&type=select";
  const fetchApiData = async (url) => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await fetch(url, settings);
      const apidata = await res.json();
      const fdata = apidata.data;
      setApiData(fdata?.map((i) => i));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchApiData(api);
  }, []);

  //delete function

  const deletedata = async(id) => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    const res = await fetch(window.harestec_service+`delete_user/${id}`, settings)
    await res.json();
    window.location.reload();
  }

  const editdata = (id) => {
    window.location.replace(window.urlmain + `edituser?type=users&id=${id}`);
  }

  dataf?.forEach((element, index) => {
    fdataobj = {
      Slno: (
        <MDBox ml={-1}>
          <MDBadge badgeContent={index + 1} color="success" variant="gradient" size="sm" />
        </MDBox>
      ),
      Name: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {element.username}
        </MDTypography>
      ),
      Email: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {element.email}
        </MDTypography>
      ),
      Phone: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {element.phone}
        </MDTypography>
      ),
      addedon: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {element.added_datetime}
        </MDTypography>
      ),
      action: (
        <MDTypography component="a"  href="#" variant="caption" color="info" fontWeight="medium">
          <div>
            <span title="edit this user"><EditIcon onClick = {(e) => {e.preventDefault(); editdata(element.id);}}/></span>
            <span title="Delete this user" style = {{color: "red", marginLeft: "5px"}}><DeleteOutlineIcon onClick = {(e) => {e.preventDefault(); deletedata(element.id);}} /></span>
          </div>
        </MDTypography>
      ),
    };
    fdataarray.push(fdataobj);
  });
  return {
    columns: [
      { Header: "Sl.no", accessor: "Slno", align: "center" },
      { Header: "Name", accessor: "Name", align: "center" },
      { Header: "Email", accessor: "Email", align: "center" },
      { Header: "Phone", accessor: "Phone", align: "center" }, 
      { Header: "Added on", accessor: "addedon", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows: fdataarray,
  };
}
