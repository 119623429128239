import React from "react";
import { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from "App";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
window.curd_service = "https://api.lotusharvestec.com/api/crud_api_service";
window.harestec_service = "https://api.lotusharvestec.com/api/";
window.urlmain = "https://learning.lotusharvestec.com/";
window.photourl = "https://learning.lotusharvestec.com/";
const clientId="674231015924-nh37diqgtd11acqm6n2sf1ra1al4mnlq.apps.googleusercontent.com"

ReactDOM.render(
  <GoogleOAuthProvider clientId={clientId}>
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <App />
    </MaterialUIControllerProvider>
  </BrowserRouter>
  </GoogleOAuthProvider>,
  document.getElementById("root")
);
