import * as React from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState, useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import { useMediaQuery } from 'react-responsive'

export default function Services() {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
    AOS.init({
        duration: 1200,
    })
    return (
        <MDBox style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} id="services">
            <MDBox style={{ width: "80%" }}>
                <MDTypography my={2} style={{ fontSize: "18px", fontWeight: "600", lineHeight: "38.73px", color: "#1E1B1B", fontFamily: "Inter" }}>
                    Our Services
                </MDTypography>
                <MDBox my={4} style={{ width: "100%" }}>
                    <MDBox my={2} p={2} data-aos="fade-right" style={{ width: "100%", display: `${isDesktopOrLaptop == true ? "flex" : "block"}`, justifyContent: "center", alignItems: "center", background: "#fff" }}>
                        <MDBox style={{ width: `${isDesktopOrLaptop == true ? "40%" : "100%"}`, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img src="/onsitevisit.jpeg" width="200px" height="200px" />
                        </MDBox>
                        <MDBox style={{ width: `${isDesktopOrLaptop == true ? "60%" : "100%"}` }}>
                            <MDBox style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <MDTypography my={2} style={{ fontSize: "18px", fontWeight: "600", color: "black", lineHeight: "38.73px", fontFamily: "Inter" }}>
                                    ON-SITE VISIT
                                </MDTypography>
                            </MDBox>
                            <MDBox style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <MDTypography my={2} style={{ fontSize: "12px", fontWeight: "300", lineHeight: "20px", color: "#1E1B1B", fontFamily: "Inter" }}>
                                    Embark on a tailored journey to resolve intricate challenges in food grain bulk storage through personalized site visits by our expert team. Uncover practical insights into design and operations, addressing complexities that demand on-site expertise. Schedule your visit and transform challenges into opportunities.
                                </MDTypography>
                            </MDBox>
                            <MDBox style={{ display: "flex", justifyContent: "start", alignItems: "start", width: "40%" }}>
                                <MDBox px={3} py={1} style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "40px", backgroundColor: "#2B5F47", color: "#fff" }}>
                                    <MDTypography style={{ fontSize: "16px", fontWeight: "500", lineHeight: "23px", color: "#fff", cursor: "pointer" }} >Enquire Now</MDTypography>
                                </MDBox>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                    <MDBox my={2} p={2} data-aos="fade-left" style={{ width: "100%", display: `${isDesktopOrLaptop == true ? "flex" : "none"}`, justifyContent: "center", alignItems: "center", background: "#fff" }}>
                        <MDBox style={{ width: "60%" }}>
                            <MDBox style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <MDTypography my={2} style={{ fontSize: "18px", fontWeight: "600", color: "black", lineHeight: "38.73px", fontFamily: "Inter" }}>
                                    LAB TESTING
                                </MDTypography>
                            </MDBox>
                            <MDBox style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <MDTypography my={2} style={{ fontSize: "12px", fontWeight: "300", lineHeight: "20px", color: "#1E1B1B", fontFamily: "Inter" }}>
                                    Unlock the full potential of your rice operations with our cutting-edge lab testing services. Discover ways to leverage existing assets for increased profits within operational budgets. Lab testing is a crucial step in enhancing value and efficiency in rice technology. Arrange your comprehensive lab testing with Lotus Harvestec to revolutionize your approach.
                                </MDTypography>
                            </MDBox>
                            <MDBox style={{ display: "flex", justifyContent: "start", alignItems: "start", width: "40%" }}>
                                <MDBox px={3} py={1} style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "40px", backgroundColor: "#2B5F47", color: "#fff" }}>
                                    <MDTypography style={{ fontSize: "16px", fontWeight: "500", lineHeight: "23px", color: "#fff", cursor: "pointer" }} >Enquire Now</MDTypography>
                                </MDBox>
                            </MDBox>
                        </MDBox>
                        <MDBox style={{ width: "40%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img src="/labtesting.jpeg" width="200px" height="200px" />
                        </MDBox>
                    </MDBox>
                    <MDBox my={2} p={2} data-aos="fade-left" style={{ width: "100%", display: `${isDesktopOrLaptop == true ? "none" : "block"}`, justifyContent: "center", alignItems: "center", background: "#fff" }}>
                        <MDBox style={{ width: `${isDesktopOrLaptop == true ? "40%" : "100%"}`, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img src="/labtesting.jpeg" width="200px" height="200px" />
                        </MDBox>
                        <MDBox style={{ width: `${isDesktopOrLaptop == true ? "60%" : "100%"}` }}>
                            <MDBox style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <MDTypography my={2} style={{ fontSize: "18px", fontWeight: "600", color: "black", lineHeight: "38.73px", fontFamily: "Inter" }}>
                                    LAB TESTING
                                </MDTypography>
                            </MDBox>
                            <MDBox style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <MDTypography my={2} style={{ fontSize: "12px", fontWeight: "300", lineHeight: "20px", color: "#1E1B1B", fontFamily: "Inter" }}>
                                    Unlock the full potential of your rice operations with our cutting-edge lab testing services. Discover ways to leverage existing assets for increased profits within operational budgets. Lab testing is a crucial step in enhancing value and efficiency in rice technology. Arrange your comprehensive lab testing with Lotus Harvestec to revolutionize your approach.
                                </MDTypography>
                            </MDBox>
                            <MDBox style={{ display: "flex", justifyContent: "start", alignItems: "start", width: "40%" }}>
                                <MDBox px={3} py={1} style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "40px", backgroundColor: "#2B5F47", color: "#fff" }}>
                                    <MDTypography style={{ fontSize: "16px", fontWeight: "500", lineHeight: "23px", color: "#fff", cursor: "pointer" }} >Enquire Now</MDTypography>
                                </MDBox>
                            </MDBox>
                        </MDBox>

                    </MDBox>
                    <MDBox my={2} p={2} data-aos="fade-right" style={{ width: "100%", display: `${isDesktopOrLaptop == true ? "flex" : "block"}`, justifyContent: "center", alignItems: "center", background: "#fff" }}>
                        <MDBox style={{ width: `${isDesktopOrLaptop == true ? "40%" : "100%"}`, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img src="/pilottesting.jpeg" width="200px" height="200px" />
                        </MDBox>
                        <MDBox style={{ width: `${isDesktopOrLaptop == true ? "60%" : "100%"}` }}>
                            <MDBox style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <MDTypography my={2} style={{ fontSize: "18px", fontWeight: "600", color: "black", lineHeight: "38.73px", fontFamily: "Inter" }}>
                                    PILOT TESTING
                                </MDTypography>
                            </MDBox>
                            <MDBox style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <MDTypography my={2} style={{ fontSize: "12px", fontWeight: "300", lineHeight: "20px", color: "#1E1B1B", fontFamily: "Inter" }}>
                                    Move beyond theory into practical implementation with our pilot testing services for rice technology. Building on the insights gained from lab testing, pilot testing validates and refines strategies for optimum results. Take the next step towards innovation by engaging in our pilot testing services and witness the transformation in your rice processes.
                                </MDTypography>
                            </MDBox>
                            <MDBox style={{ display: "flex", justifyContent: "start", alignItems: "start", width: "40%" }}>
                                <MDBox px={3} py={1} style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "40px", backgroundColor: "#2B5F47", color: "#fff" }}>
                                    <MDTypography style={{ fontSize: "16px", fontWeight: "500", lineHeight: "23px", color: "#fff", cursor: "pointer" }} >Enquire Now</MDTypography>
                                </MDBox>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                    <MDBox my={2} p={2} data-aos="fade-left" style={{ width: "100%", display: `${isDesktopOrLaptop == true ? "flex" : "none"}`, justifyContent: "center", alignItems: "center", background: "#fff" }}>
                        <MDBox style={{ width: "60%" }}>
                            <MDBox style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <MDTypography style={{ fontSize: "18px", fontWeight: "600", color: "black", lineHeight: "38.73px", fontFamily: "Inter" }}>
                                    COMMERCIAL SCALE MODELLING
                                </MDTypography>
                            </MDBox>
                            <MDBox style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <MDTypography my={2} style={{ fontSize: "12px", fontWeight: "300", lineHeight: "20px", color: "#1E1B1B", fontFamily: "Inter" }}>
                                    Gain a strategic advantage with our commercial scale modelling services in rice technology. After three years of test marketing, our experts extrapolate profit or loss scenarios, factoring in CAPEX and OPEX. This service provides a comprehensive view of the financial landscape, enabling you to make informed decisions for sustained success. Explore the future of rice technology with Lotus Harvestec.
                                </MDTypography>
                            </MDBox>
                            <MDBox style={{ display: "flex", justifyContent: "start", alignItems: "start", width: "40%" }}>
                                <MDBox px={3} py={1} style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "40px", backgroundColor: "#2B5F47", color: "#fff" }}>
                                    <MDTypography style={{ fontSize: "16px", fontWeight: "500", lineHeight: "23px", color: "#fff", cursor: "pointer" }} >Enquire Now</MDTypography>
                                </MDBox>
                            </MDBox>
                        </MDBox>
                        <MDBox style={{ width: "40%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img src="/csm.jpeg" width="200px" height="200px" />
                        </MDBox>
                    </MDBox>
                    <MDBox my={2} p={2} data-aos="fade-left" style={{ width: "100%", display: `${isDesktopOrLaptop == true ? "flex" : "block"}`, justifyContent: "center", alignItems: "center", background: "#fff" }}>
                        <MDBox style={{ width: `${isDesktopOrLaptop == true ? "40%" : "100%"}`, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img src="/csm.jpeg" width="200px" height="200px" />
                        </MDBox>
                        <MDBox style={{ width: `${isDesktopOrLaptop == true ? "60%" : "100%"}` }}>
                            <MDBox style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <MDTypography style={{ fontSize: "18px", fontWeight: "600", color: "black", lineHeight: "38.73px", fontFamily: "Inter" }}>
                                    COMMERCIAL SCALE MODELLING
                                </MDTypography>
                            </MDBox>
                            <MDBox style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <MDTypography my={2} style={{ fontSize: "12px", fontWeight: "300", lineHeight: "20px", color: "#1E1B1B", fontFamily: "Inter" }}>
                                    Gain a strategic advantage with our commercial scale modelling services in rice technology. After three years of test marketing, our experts extrapolate profit or loss scenarios, factoring in CAPEX and OPEX. This service provides a comprehensive view of the financial landscape, enabling you to make informed decisions for sustained success. Explore the future of rice technology with Lotus Harvestec.
                                </MDTypography>
                            </MDBox>
                            <MDBox style={{ display: "flex", justifyContent: "start", alignItems: "start", width: "40%" }}>
                                <MDBox px={3} py={1} style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "40px", backgroundColor: "#2B5F47", color: "#fff" }}>
                                    <MDTypography style={{ fontSize: "16px", fontWeight: "500", lineHeight: "23px", color: "#fff", cursor: "pointer" }} >Enquire Now</MDTypography>
                                </MDBox>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </MDBox>
        </MDBox>
    )
}

