import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import { useEffect, useState } from "react";

export default function data() {
  const [dataf, setApiData] = useState();
  let fdataobj = {};
  const fdataarray = [];
  const api = window.harestec_service + "getUserPaymentTxnAPI";
  const fetchApiData = async (url) => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await fetch(url, settings);
      const apidata = await res.json();
      const fdata = apidata.data;
      setApiData(fdata?.map((i) => i));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchApiData(api);
  }, []);

  //delete function

  const deletedata = async(id) => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    const res = await fetch(window.harestec_service+`delete_user/${id}`, settings)
    await res.json();
    window.location.reload();
  }

  dataf?.forEach((element, index) => {
    fdataobj = {
      Slno: (
        <MDBox ml={-1}>
          <MDBadge badgeContent={index + 1} color="success" variant="gradient" size="sm" />
        </MDBox>
      ),
      Name: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {element.username}
        </MDTypography>
      ),
      time: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {element.txn_time}
        </MDTypography>
      ),
      Status: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {element.status}
        </MDTypography>
      ),
      item: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {element.name}
        </MDTypography>
      ),
      Amount: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {element.amt}
        </MDTypography>
      ),
    };
    fdataarray.push(fdataobj);
  });
  return {
    columns: [
      { Header: "Sl.no", accessor: "Slno", align: "center" },
      { Header: "Name", accessor: "Name", align: "center" },
      { Header: "Added by", accessor: "time", align: "center" },
      { Header: "Status", accessor: "Status", align: "center" },
      { Header: "Purchase Item", accessor: "item", align: "center" },
      { Header: "Amount", accessor: "Amount", align: "center" },
    ],
    rows: fdataarray,
  };
}
