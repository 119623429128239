import * as React from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState, useEffect } from "react";

function Mybook(){

    return (
        <>
            <MDBox style={{width: "100%", height: "86px", backgroundColor: "#ffffff", borderBottom: "0.5px solid #2E3192", display: "flex", jsutifyContent: "center", alignItems: "center" }}>
                <MDBox style={{width: "50%", height: "86px", display: "flex", jsutifyContent: "start", alignItems: "center"}}>
                    <MDTypography mx={2} style={{fontSize: '16px', lineHeight: "36px", fontWeight: "700", color: "#151E30"}}>My Books</MDTypography>
                </MDBox>
                <MDBox style={{width: "50%", height: "86px", display: "flex", justifyContent: "end", alignItems: "center"}}>
                    <MDBox mr={4} style={{width: "240px", display: "flex", justifyContent: "end", alignItems: "center"}}>
                        <a href='/client'><MDTypography mx={2} style={{fontSize: '14px', lineHeight: "16.41px", fontWeight: "500", color: "#151E30", cursor: "pointer"}}>Home</MDTypography></a>
                        <img src='/profile.png' style={{marginLeft: "10px", borderRadius: '50%'}}/>
                        <MDTypography mx={1} style={{fontSize: '14px', lineHeight: "16.41px", fontWeight: "500", color: "#151E30"}}>{localStorage.getItem('client') == undefined ? 'Guest' : JSON.parse(localStorage.getItem('client'))[0].username}</MDTypography>
                        <img src='/bellicon.png' style={{marginLeft: "20px"}}/>
                    </MDBox>
                </MDBox>
            </MDBox>
        </>
    )
}

export default Mybook;