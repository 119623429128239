import * as React from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState, useEffect } from "react";
import { useMediaQuery } from 'react-responsive'

function Resourcessidenav({datafromsidenav}) {

    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
    useEffect(() => {
        const getalltabs = document.getElementsByClassName('tabs')
        const tabselement = document.getElementsByClassName('tabselement')
        getalltabs[0].style.backgroundColor = "#2B5F47"
        tabselement[0].style.color = "#FFF"
    }, []);

    function tabfunct(data, index){
        datafromsidenav(data)
        const getalltabs = document.getElementsByClassName('tabs')
        const tabselement = document.getElementsByClassName('tabselement')
        Array.prototype.forEach.call(getalltabs, function(elem) {
            elem.style.backgroundColor = "#fff"
        });
        Array.prototype.forEach.call(tabselement, function(elem) {
            elem.style.color = "black"
        });
        getalltabs[index].style.backgroundColor = "#2B5F47"
        tabselement[index].style.color = "#FFF"
    }

    return (
        <>
            <MDBox style={{ height: "100vh", width: `${isDesktopOrLaptop == true ? "240px" : "0px"}`, display: `${isDesktopOrLaptop == true ? "block" : "none"}`,  padding: "32px 20px 56px 20px", backgroundColor: "#fff" }}>
                <img src="/logo.png" alt="brand logo" style={{ height: "38px", width: "127px" }} />
                <MDBox my={5} style={{ height: "168px", width: "221px" }}>
                    <MDBox className="tabs" onClick={(e) => { e.preventDefault(); tabfunct(35, 0) }} style={{ height: "48px", width: "201px", display: "flex", justifyContent: "start", alignItems: "center" }}>
                        <img src="/Vectorres.png" style={{marginLeft: "10px"}}/>
                        <MDTypography className="tabselement" mx={2} style={{ fontSize: "14px", fontWeight: "600", lineHeight: '18.75px', color: "#ffffff", cursor: "pointer" }}>Bulk Storage</MDTypography>
                    </MDBox>
                    <MDBox className="tabs" onClick={(e) => { e.preventDefault(); tabfunct(36, 1) }} style={{ height: "48px", width: "201px", display: "flex", justifyContent: "start", alignItems: "center" }}>
                        <img src="/Vectorres1.png" style={{marginLeft: "10px"}}/>
                        <MDTypography className="tabselement" mx={2} style={{ fontSize: "14px", fontWeight: "600", lineHeight: '18.75px', cursor: "pointer" }}>Rice Technology</MDTypography>
                    </MDBox>
                    <MDBox className="tabs" onClick={(e) => { e.preventDefault(); tabfunct('vc', 2) }} style={{ height: "48px", width: "201px", display: "flex", justifyContent: "start", alignItems: "center" }}>
                        <img src="/Vectorres1.png" style={{marginLeft: "10px"}}/>
                        <MDTypography className="tabselement" mx={2} style={{ fontSize: "14px", fontWeight: "600", lineHeight: '18.75px', cursor: "pointer" }}>Video Assistance</MDTypography>
                    </MDBox>
                </MDBox>
            </MDBox>
        </>
    )
}

export default Resourcessidenav;