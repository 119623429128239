import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import { useState, useEffect } from "react";
import {AlertServices} from './../../services/alert.service'

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

const mystyle = {
  ul : {
    marginLeft: "20px",
  },
};


function Topics() {

  function addtopics(){
    window.location.replace(`/edit?divison=insert_into_topic&type=insert&id=0&apiwork=topics`);
  }

  // card functionality

  const [dataf, setApiData] = useState();
  const api = window.curd_service + "?key_params=topics&type=select";
  const fetchApiData = async (url) => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await fetch(url, settings);
      const apidata = await res.json();
      const fdata = apidata.data;
      setApiData(fdata?.map((i) => i));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchApiData(api);
  }, []);

  //delete card functionality

  const deletedata = async (id) => {
    AlertServices({
      title : 'Are you sure?' ,
      text : "Do you want to delete this data!" ,
      icon : 'warning' ,
      apiCall : "Y",
      apiHandler : window.curd_service + `?key_params=topics_id_delete&type=delete&id=${id}`,
      type : 'delete',
      dismissMassage : 'Your imaginary data is safe :)'
    }) 
  }

  // edit card functionality

  const editdata = (id) => {
    console.log(id);
    window.location.replace(window.urlmain+`edit?divison=edittopic&type=update&id=${id}&apiwork=update_topics`);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Button variant="contained" onClick={addtopics} style={{ color: "#ffffff", float: "right", marginRight: "20px", backgroundColor: "#3bad21" }}>Add topics</Button>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Topics
                </MDTypography>
              </MDBox>
              <MDBox pt={3} style={{ margin: "auto" }}>
              {dataf?.map((e) => (
                  <Card sx={{ maxWidth: 750, minWidth: 750 }} style={{ margin: "20px" }}>
                    {/* <CardMedia
                      component="img"
                      alt="chapter image"
                      height="140"
                      image="http://localhost:5000/image/Screenshot_20221222_015623.png"
                    /> */}
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {e.name}
                      </Typography>
                      <Typography gutterBottom variant="body2" component="div" style={{backGround: "#ebebeb"}}>
                        {e.descshort}
                      </Typography>
                      <Typography gutterBottom variant="body2" component="div" style={{backGround: "#ebebeb"}}>
                        Charges : {e.amount_inr} INR / {e.amount_usd} USD
                      </Typography>
                      <MDBox variant="body2" color="text.secondary">
                        <div style={mystyle} dangerouslySetInnerHTML={{ __html: e.descs }}>
                        </div>
                      </MDBox>
                    </CardContent>
                    <CardActions>
                      <Button size="small" onClick = {(event) => {event.preventDefault(); editdata(e.id);}}>Edit</Button>
                      <Button size="small" onClick={(event) => { event.preventDefault(); deletedata(e.id); }} style={{ color: "red" }}>Del</Button>
                    </CardActions>
                  </Card>
                ))
                }
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Topics;
