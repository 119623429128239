import * as React from 'react';
import MDBox from "components/MDBox";
import Button from "@mui/material/Button";
import MDTypography from "components/MDTypography";
import image from './../../layouts/client/bannerpic.jpg';
import image1 from './../../layouts/client/wbs.jpg';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import AOS from "aos";
import 'react-awesome-slider/dist/styles.css';
import "aos/dist/aos.css";
import { useMediaQuery } from 'react-responsive';

const AutoplaySlider = withAutoplay(AwesomeSlider);

function MainLandingBlock() {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })

    AOS.init({
        duration: 1200,
    })

    const firstshopbtndiv = { width: "100%", height: "400px", background: `url(${image})`, backgroundRepeat: "no-repeat", backgroundSize: "100% 100%" };
    const firstshopbtndiv1 = { width: "100%", height: "400px", background: `url(${image1})`, backgroundRepeat: "no-repeat", backgroundSize: "100% 100%" };
    const customdivonimage = { width: `${isDesktopOrLaptop == true ? '50%' : '100%'}`, height: "25%", display: "block", justifyContent: "center", alignItems: "center" };
    const customdivonimagesub = { display: "block", justifyContent: "center", alignItems: "center" };
    const textstylecus = { fontSize: "20px", fontWeight: "600", color: "#fff", fontFamily: "Inter" };

    return (
        <>
            <AwesomeSlider bullets={false} style={{ height: `${isDesktopOrLaptop == true ? '400px' : '700px'}` }}>
                <MDBox style={firstshopbtndiv1}>
                    <MDBox data-aos="fade-right" style={customdivonimage}>
                    </MDBox>
                </MDBox>
                <MDBox style={firstshopbtndiv}>
                    <MDBox data-aos="fade-right" style={customdivonimage}>
                    </MDBox>
                </MDBox>
            </AwesomeSlider>
        </>
    )
}

export default MainLandingBlock