import * as React from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useMediaQuery } from 'react-responsive'

function Navbar({ getmodalchangestats }) {

    let [login, setLogin] = useState(() => {
        if(localStorage.getItem('client') == 'null'){
            return JSON.parse(localStorage.getItem('client')).id
        }else{
            return false;
        }
    })
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })

    useEffect(() => {
        AOS.init();
    }, []);

    const [show, setShow] = useState({ "login": "hide", "signup": 'hide' });

    const [dropdown, setDropDown] = useState(false)

    const handledropdown = () => {
        dropdown == false ? setDropDown(true) : setDropDown(false);
    }

    const basestyle = { width: "100%", height: "88px", background: '#FDF9F5', display: "flex", justifyContent: "center", alignItems: "center" };
    const headerleftstyle = { width: "40%", display: "flex", justifyContent: "start", alignItems: "center" };
    const headerrigthstyle = { width: "60%", display: `${isDesktopOrLaptop == true ? "flex" : "none"}`, justifyContent: "end", alignItems: "center" };
    const headerrigthstylemobile = { width: "60%", display: `${isDesktopOrLaptop == true ? "none" : dropdown == false ? "flex" : "block"}`, justifyContent: "end", alignItems: "center" };
    const textstyle = { fontSize: "14px", fontWeight: "500", lineHeight: "23px", cursor: "pointer", fontFamily: "Inter" };
    const loginbox = { borderRadius: "40px", backgroundColor: "#2B5F47", display: "flex", justifyContent: "end", alignItems: "center" };

    const hideandshowmodal = () => {
        window.open(`/authentication/sign-inclient?type=nonbuy`)
    }

    const hideandshowmodallogout = () => {
        localStorage.clear();
        window.location.reload();
    }

    const signupmodal = () => {
        let data = {}
        show.signup == "hide" ? data = { "login": "hide", "signup": 'show' } : data = { "login": "hide", "signup": 'hide' };
        getmodalchangestats(data)
    }

    const handlelogout = () => {

    }

    return (
        <>
            <MDBox data-aos="fade-down" style={basestyle}>
                <MDBox mx={isDesktopOrLaptop == true ? 4 : 1} style={headerleftstyle}>
                    <img src="/logo.png" alt="brand logo" style={{ height: "45px" }} />
                </MDBox>
                <MDBox style={headerrigthstylemobile}>
                    <MDBox style={{ width: "100%", height: "86px", display: "flex", justifyContent: "end", alignItems: "center" }}>
                        <img onClick={handledropdown} src="hammburger.png" alt="hamm logo" style={{ height: "45px" }} />
                    </MDBox>
                    <MDBox mx={2} style={{ width: "100%", height: "181px", display: `${dropdown == true ? "block" : "none"}`, background: "#fff", zIndex: "10", position: "absolute" }}>
                        <MDBox style={{ width: "100%", height: "45px", display: "flex", justifyContent: "start", alignItems: "center", zIndex: "10", borderBottom: "0.5px solid #2B5F47" }}>
                            <a href='/resources'><MDTypography mx={1} style={textstyle}>Resources</MDTypography></a>
                        </MDBox>
                        <MDBox style={{ width: "100%", height: "45px", display: "flex", justifyContent: "start", alignItems: "center", zIndex: "10", borderBottom: "0.5px solid #2B5F47" }}>
                            <a href='#services'><MDTypography mx={1} style={textstyle}>Services</MDTypography></a>
                        </MDBox>
                        <MDBox style={{ width: "100%", height: "45px", display: "flex", justifyContent: "start", alignItems: "center", zIndex: "10", borderBottom: "0.5px solid #2B5F47" }}>
                            <a href="#aboutauthor"><MDTypography mx={1} style={textstyle}>About Author</MDTypography></a>
                        </MDBox>
                        <MDBox style={{ width: "100%", height: "45px", display: "flex", justifyContent: "start", alignItems: "center", zIndex: "10", borderBottom: "0.5px solid #2B5F47" }}>
                            {localStorage.getItem('client') != undefined ? (<a href='/mytransaction'><MDTypography mx={1} style={textstyle}>My Books</MDTypography></a>) : (<MDTypography onClick={signupmodal} mx={1} style={textstyle}>Sign Up</MDTypography>)}
                        </MDBox>
                        <MDBox style={{ width: "100%", height: "45px", display: "flex", justifyContent: "start", alignItems: "center", zIndex: "10", borderBottom: "0.5px solid #2B5F47" }}>
                            {localStorage.getItem('client') != undefined ? (<MDBox mx={1} px={3} py={1} onClick={hideandshowmodallogout} style={loginbox}><MDTypography mx={2} style={{ fontSize: "14px", fontWeight: "500", lineHeight: "23px", cursor: "pointer", fontFamily: "Inter", color: "#fff" }}>Log Out</MDTypography></MDBox>) : (<MDBox mx={1} px={3} py={1} onClick={hideandshowmodal} style={loginbox}><MDTypography mx={2} style={{ fontSize: "14px", fontWeight: "500", lineHeight: "23px", cursor: "pointer", fontFamily: "Inter", color: "#fff" }}>Login</MDTypography></MDBox>)}
                        </MDBox>
                    </MDBox>
                </MDBox>
                <MDBox style={headerrigthstyle} mr={1}>
                    <a href='/resources'><MDTypography mx={1} style={textstyle}>Resources</MDTypography></a>
                    <a href='#services'><MDTypography mx={1} style={textstyle}>Services</MDTypography></a>
                    <a href="#aboutauthor"><MDTypography mx={1} style={textstyle}>About Author</MDTypography></a>
                    {localStorage.getItem('client') != undefined ? (<a href='/mytransaction'><MDTypography mx={2} style={textstyle}>My Books</MDTypography></a>) : (<MDTypography onClick={signupmodal} mx={1} style={textstyle}>Sign Up</MDTypography>)}
                    {localStorage.getItem('client') != undefined ? (<MDBox mx={1} px={3} py={1} onClick={hideandshowmodallogout} style={loginbox}><MDTypography mx={2} style={{ fontSize: "14px", fontWeight: "500", lineHeight: "23px", cursor: "pointer", fontFamily: "Inter", color: "#fff" }}>Log Out</MDTypography></MDBox>) : (<MDBox mx={1} px={3} py={1} onClick={hideandshowmodal} style={loginbox}><MDTypography mx={2} style={{ fontSize: "14px", fontWeight: "500", lineHeight: "23px", cursor: "pointer", fontFamily: "Inter", color: "#fff" }}>Login</MDTypography></MDBox>)}
                </MDBox>
            </MDBox>
        </>
    );
}

export default Navbar;