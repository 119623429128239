import { useState, useEffect } from "react";
import { ReactReader } from 'react-reader';
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";

function LandingPage() {

  return (
    <div style={{ width: "100%", height: "100vh",display: "block" }}>
      <MDBox style={{width: "99%", height: "75px", backgroundColor: "#FFBD59", margin: "7px", borderRadius: "12px"}}>
        <MDBox className = "mt-3" style={{width: "99%", height: "450px", display: "flex", justifyContent: "center", alignItems: "center", margin: "20px 7px 0 0", borderRadius: "12px"}}>
            <MDBox style={{width: "23%", height: "450px",  margin: "7px", borderRadius: "12px", backgroundColor: "#FFBD59"}}>

            </MDBox>
            <MDBox style={{width: "75%", height: "450px",  margin: "7px", borderRadius: "12px"}}>

            </MDBox>
        </MDBox>
      </MDBox>
    </div>
  );
}

export default LandingPage;


