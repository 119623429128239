import Swal from 'sweetalert2'
// import 'sweetalert2/src/sweetalert2.scss'

const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
})

function swalfirefunctionality(title, text, icon){
    swalWithBootstrapButtons.fire(
        `${title}`,
        `${text}`,
        `${icon}`
    )
}

export function AlertServices(props) {
    swalWithBootstrapButtons.fire({
        title: `${props.title}`,
        text: `${props.text}`,
        icon: `${props.icon}`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
    }).then(async (result) => {
        if (result.isConfirmed) {
            if (props.apiCall == "Y") {
                const res = await fetch(props.apiHandler, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                })
                swalfirefunctionality('Deleted!', 'Your data has been deleted.', 'success')
            }else{
                swalfirefunctionality(props.type, `Your data has been ${props.type+'ed'}.`, 'success')
            }
        } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
        ) {
            swalfirefunctionality( 'Cancelled', props.dismissMassage, 'error')
        }
    })
}