import * as React from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMediaQuery } from 'react-responsive'
import image from './authorcoverpic.jpeg'

function AboutAuthor() {
    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
    

    return (
        <>
            <MDBox data-aos="flip-left" style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", background: `url(${image})`, backgroundRepeat: "no-repeat", backgroundSize: "100% 100%" }} id="aboutauthor">
                <MDBox style={{ width: "80%", display: `${isDesktopOrLaptop == true ? 'flex' : 'block'}`, alignItems: "center", justifyContent: "center" }}>
                    <MDBox style={{ width: `${isDesktopOrLaptop == true ? '40%' : '100%'}`, backgroundColor: "#FFF", margin: "10px", height: "362.73px" }}>
                        <MDBox my={2} style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img src='/aboutauthor.png' style={{ height: "144px", width: "144px" }} />
                        </MDBox>
                        <MDBox mx={2} style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <MDTypography style={{ fontSize: "18px", fontWeight: "600", lineHeight: "38.73px", color: "#B67F38", fontFamily: "Inter" }}>Munishwar Vasudeva</MDTypography>
                        </MDBox>
                        <MDBox my={1} style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <MDTypography style={{ fontSize: "14px", fontWeight: "400", lineHeight: "23px", color: "#433C3C", fontFamily: "Inter", alignText : "center", textAlign: "center" }}>M.Tech in Post-Harvest Engineering from the Indian Institute of Technology, Kharagpur</MDTypography>
                        </MDBox>
                        <MDBox my={1} style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <MDTypography style={{ fontSize: "14px", fontWeight: "400", lineHeight: "23px", color: "#433C3C", fontFamily: "Inter"  }}>Experience : 37+ years</MDTypography>
                        </MDBox>
                    </MDBox>
                    <MDBox style={{ width: `${isDesktopOrLaptop == true ? '60%' : '100%'}`, height: "100%", backgroundColor: "#fff" }}>
                        <MDTypography mx={4} mt={4} style={{ fontSize: "18px", fontWeight: "600", lineHeight: "38.73px", color: "#1E1B1B", fontFamily: "Inter"  }}>About the author</MDTypography>
                        <MDTypography mx={4} my={1} style={{ fontSize: "14px", fontWeight: "400", lineHeight: "23px", color: "#433C3C", fontFamily: "Inter", textAlign: "justify"  }}>
                            Mr. Munishwar Vasudeva, a distinguished figure in the realm of post-harvest management and food grain handling, serves as the Managing Director of Lotus Harvestec Private Limited. His role epitomizes his invaluable contributions and leadership in the post harvest-space, backed by a career spanning over 37 years dedicated to advancing the global agro business-industry.
                            Mr. Vasudeva's academic foundation, consisting of an M.Tech in Post-Harvest Engineering from the Indian Institute of Technology, Kharagpur, and a B.Tech in Agricultural Engineering from Punjab Agricultural University, has underpinned his extensive contributions to the field.
                            Among his notable achievements is his pioneering work in conceptualizing and designing bulk storage solutions for various food grains, including paddy, rice, wheat, maize, and oilseeds.
                        </MDTypography>
                    </MDBox>
                </MDBox>
            </MDBox>
        </>
    )
}

export default AboutAuthor