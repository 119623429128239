import * as React from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMediaQuery } from 'react-responsive';
import { useState, useEffect } from "react";

function ResourcesNav({datafromnav}) {

    const [dropdown, setDropDown] = useState(false)

    const handledropdown = () => {
        dropdown == false ? setDropDown(true) : setDropDown(false);
    }

    useEffect(() => {
        const getalltabs = document.getElementsByClassName('tabs')
        const tabselement = document.getElementsByClassName('tabselement')
        getalltabs[0].style.backgroundColor = "#2B5F47"
        tabselement[0].style.color = "#FFF"
    }, []);

    function tabfunct(data, index){
        setDropDown(false)
        datafromnav(data)
        const getalltabs = document.getElementsByClassName('tabs')
        const tabselement = document.getElementsByClassName('tabselement')
        Array.prototype.forEach.call(getalltabs, function(elem) {
            elem.style.backgroundColor = "#fff"
        });
        Array.prototype.forEach.call(tabselement, function(elem) {
            elem.style.color = "black"
        });
        getalltabs[index].style.backgroundColor = "#2B5F47"
        tabselement[index].style.color = "#FFF"
    }

    const handlelogout = () => {
        localStorage.clear();
        window.open('/client', "_self")
    }

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })

    return (
        <>
            <MDBox style={{ width: "100%", height: "86px", backgroundColor: "#ffffff", borderBottom: "0.5px solid #2E3192", display: "flex", jsutifyContent: "center", alignItems: "center" }}>
                <MDBox style={{ width: "50%", height: "86px", display: `${isDesktopOrLaptop == true ? "none" : "flex"}`, jsutifyContent: "start", alignItems: "center" }}>
                    <img src="/logo.png" alt="brand logo" style={{ height: "45px" }} />
                </MDBox>
                <MDBox mx={2} style={{ width: "50%", height: "86px", display: `${isDesktopOrLaptop == true ? "none" : dropdown == false ? "flex" : "block"}`, justifyContent: "end", alignItems: "center" }}>
                    <MDBox style={{ width: "100%", height: "86px", display: "flex", justifyContent: "end", alignItems: "center" }}>
                        <img onClick={handledropdown} src="hammburger.png" alt="hamm logo" style={{ height: "45px" }} />
                    </MDBox>
                    <MDBox mx={2} style={{ width: "100%", display: `${dropdown == true ? "block" : "none"}`, background: "#fff", zIndex: "10", position: "absolute" }}>
                        <MDBox style={{ width: "100%", height: "45px", display: "flex", justifyContent: "start", alignItems: "center", zIndex: "10", borderBottom : "0.5px solid #2B5F47" }}>
                            {/* <img src="/Vectorres.png" style={{ marginLeft: "10px" }} /> */}
                            <MDTypography className="tabselement" mx={2} style={{ fontSize: "14px", fontWeight: "600", lineHeight: '18.75px', color: "black", cursor: "pointer" }}>Profile</MDTypography>
                        </MDBox>
                        <MDBox style={{ width: "100%", height: "45px", display: `${localStorage.getItem('client') == undefined ? 'none' : "flex"}`, justifyContent: "start", alignItems: "center", zIndex: "10", borderBottom : "0.5px solid #2B5F47" }}>
                            {/* <img src="/Vectorres.png" style={{ marginLeft: "10px" }} /> */}
                            <a href='/mytransaction'><MDTypography mx={2} style={{ fontSize: '14px', lineHeight: "16.41px", fontWeight: "600", color: "black" }}>{localStorage.getItem('client') == undefined ? '' : 'My Books'}</MDTypography></a>
                        </MDBox>
                        <MDBox className="tabs" style={{ width: "100%", height: "40px", display: "flex", justifyContent: "start", alignItems: "center", zIndex: "10", borderBottom : "0.5px solid #2B5F47" }}>
                            {/* <img src="/Vectorres.png" style={{ marginLeft: "10px" }} /> */}
                            <MDTypography className="tabselement" mx={2} style={{ fontSize: "14px", fontWeight: "600", lineHeight: '18.75px', color: "black", cursor: "pointer" }} onClick={(e) => { e.preventDefault(); tabfunct(35, 0) }}>Bulk Storage</MDTypography>
                        </MDBox>
                        <MDBox className="tabs" style={{ width: "100%", height: "40px", display: "flex", justifyContent: "start", alignItems: "center", zIndex: "10", borderBottom : "0.5px solid #2B5F47" }}>
                            {/* <img src="/Vectorres.png" style={{ marginLeft: "10px" }} /> */}
                            <MDTypography className="tabselement" mx={2} style={{ fontSize: "14px", fontWeight: "600", lineHeight: '18.75px', color: "black", cursor: "pointer" }} onClick={(e) => { e.preventDefault(); tabfunct(36, 1) }}>Rice Technology</MDTypography>
                        </MDBox>
                        <MDBox className="tabs" style={{ width: "100%", height: "40px", display: "flex", justifyContent: "start", alignItems: "center", zIndex: "10", borderBottom : "0.5px solid #2B5F47" }}>
                            {/* <img src="/Vectorres.png" style={{ marginLeft: "10px" }} /> */}
                            <MDTypography className="tabselement" mx={2} style={{ fontSize: "14px", fontWeight: "600", lineHeight: '18.75px', color: "black", cursor: "pointer" }} onClick={(e) => { e.preventDefault(); tabfunct('mp', 2) }}>My transaction</MDTypography>
                        </MDBox>
                        <MDBox className="tabs" style={{ width: "100%", height: "40px", display: "flex", justifyContent: "start", alignItems: "center", zIndex: "10", borderBottom : "0.5px solid #2B5F47" }}>
                            {/* <img src="/Vectorres.png" style={{ marginLeft: "10px" }} /> */}
                            <MDTypography className="tabselement" mx={2} style={{ fontSize: "14px", fontWeight: "600", lineHeight: '18.75px', color: "black", cursor: "pointer" }} onClick={(e) => { e.preventDefault(); tabfunct('vc', 3) }}>Video Assistance</MDTypography>
                        </MDBox>
                    </MDBox>
                </MDBox>
                <MDBox style={{ width: "100%", height: "86px", display: `${isDesktopOrLaptop == true ? "flex" : "none"}`, justifyContent: "end", alignItems: "center" }}>
                    <MDBox mr={4} style={{ width: "360px", display: "flex", justifyContent: "end", alignItems: "center" }}>
                        <a href='/client'><MDTypography mx={2} style={{ fontSize: '14px', lineHeight: "16.41px", fontWeight: "500", color: "#151E30", cursor: "pointer" }}>Home</MDTypography></a>
                        <a href='/mytransaction'><MDTypography mx={1} style={{ fontSize: '14px', lineHeight: "16.41px", fontWeight: "500", color: "#151E30" }}>{localStorage.getItem('client') == undefined ? '' : 'My Books'}</MDTypography></a>
                        <img src='/profile.png' style={{ marginLeft: "10px", borderRadius: '50%' }} />
                        <MDTypography mx={1} style={{ fontSize: '14px', lineHeight: "16.41px", fontWeight: "500", color: "#151E30" }}>{localStorage.getItem('client') == undefined ? 'Guest' : JSON.parse(localStorage.getItem('client'))[0].username}</MDTypography>
                        <img src='/power-off.png' style={{ marginLeft: "20px", height: "20px", width: "20px", cursor: "pointer" }} onClick={handlelogout}/>
                    </MDBox>
                </MDBox>
            </MDBox>
        </>
    )
}

export default ResourcesNav;