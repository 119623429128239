// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import {useEffect, useState} from "react";

// Material Dashboard 2 React components

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import Button from "@mui/material/Button";

function Media() {
  const [getApiData, setApiData] = useState();
  const api = window.harestec_service + "gettbl_media";
  const fetchApiData = async (url) => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await fetch(url, settings);
      const apidata = await res.json();
      const fdata = apidata.data;
      setApiData(fdata?.map((i) => i));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchApiData(api);
  }, []);


  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <img src={image} name={name} size="sm" style={{height: "30px", width: "30px"}} />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const addmedia = () => {
    window.location.replace(window.urlmain + `editmedia?type=media`);
  }

  const deletedata = async (id) => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    const res = await fetch(window.harestec_service+`deletedtbl_media/${id}`, settings)
    await res.json();
    window.location.reload();
  }

  let fdataobj = {};
  const fdataarray = [];

  getApiData?.forEach((element, index) => {
    let imageurl = `https://learning.lotusharvestec.com/image/${element.media_name}`
    fdataobj = {
      Name: <Author image={imageurl} name={element.name} email="" />,
        Media: <Job title={element.media_name} description="" />,
        Typeof: (
          <MDBox ml={-1}>
            <MDBadge badgeContent={element.type} color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
       
        action: (
          <MDTypography onClick = {(e) => {e.preventDefault(); deletedata(element.media_id)}} component="a" href="#" variant="caption" color="warning" fontWeight="medium">
            Del
          </MDTypography>
        ),
    };
    fdataarray.push(fdataobj);
  });
   
  const columns = [
      { Header: "Name", accessor: "Name", width: "45%", align: "left" },
      { Header: "Media", accessor: "Media", align: "left" },
      { Header: "Typeof", accessor: "Typeof", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ]
  const rows= fdataarray;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Button variant="contained" onClick={addmedia} style={{ color: "#ffffff", float: "right", marginRight: "20px", backgroundColor: "#3bad21" }}>Add media</Button>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Media Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Media;
