import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import "jodit/build/jodit.min.css";
import JoditEditor from 'jodit-react';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDInput from "components/MDInput";

const styles = {
  Appheader: {
    backgroundColor: "#ffffff",
    maxHeight: "2vh",
    display: "flex",
    flexDirection: "column",
    fontSize: "calc(10px + 1vmin)",
    color: "black",
    marginBottom: "5vh",
    textAlign: "center",
    padding: "12px",
  },
  App1: {
    backgroundColor: "#ffffff",
    height: "190px",
    display: "flex",
    flexDirection: "column",
    fontSize: "calc(10px + 1vmin)",
    color: "black",
    marginBottom: "5vh",
    textAlign: "center",
    padding: "50px",
    width: "100%",
    margin: "3% 0 3% 0",
  },
  App: {
    minHeight: "53vh",
    backgroundColor: "#ffffff",
  },
  App2: {
    height: "150px",
    width: "150px",
    margin: "20px"
  }
};



function Package() {
  const queryParameters = new URLSearchParams(window.location.search);
  const divison = queryParameters.get("divison");
  const type = queryParameters.get("type");
  const id = queryParameters.get("id");
  const apiwork = queryParameters.get("apiwork");
  const [getIdBasedData, setGetIdBasedData] = useState("");
  const [getParentBasedData, setGetParentBasedData] = useState([]);
  const [getParent, setParent] = useState("");
  const [getEditorElemeny, setEditorElement] = useState();
  const editor = useRef(null);
  const [content, setContent] = useState('');
  const [image, setImage] = useState();
  const [ getAllImage , setAllImage] = useState();

  const config =
  {
    placeholder: 'Start typings...'
  }
    ;

  // workinf on work base api

  const fetchApiData1 = async (url) => {
    const querryname = document.getElementById("querryname").value;
    const descriptionshort = document.getElementById("descriptionshort").value;
    const amount = document.getElementById("amount").value;
    const data = {
      "id": id,
      "name": querryname,
      "desc": content,
      "descshort": descriptionshort,
      "amount": amount,
      "image_name": image.name,
      "added_by": "1",
      "parent_id": getParent,
    }
    console.log(data);
    const settings = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await fetch(url, settings);
      await res.json();
      // window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const fetchApiData = async (url) => {
    const querryname = document.getElementById("querryname").value;
    const descriptionshort = document.getElementById("descriptionshort").value;
    const amount = document.getElementById("amount").value;
    const data = {
      "id": id,
      "name": querryname,
      "desc": content,
      "descshort": descriptionshort,
      "amount": amount,
      "image_name": image.name,
      "added_by": "1",
      "parent_id": getParent,
    }
    console.log(data);
    const settings = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await fetch(url, settings);
      await res.json();
      // window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  // working on addition

  function adddata() {
    console.log("line no 73");
    const api = window.harestec_service + `${apiwork}`;
    fetchApiData(api);
  }

  // working on update

  function editdata() {
    console.log("line no 73");
    const api = window.harestec_service + `${apiwork}`;
    fetchApiData1(api);
  }

  // calling function on the basis of worktype

  function onsubmitdata() {
    { type == "insert" ? adddata() : editdata() }
  }


  // get data on the basis of id 

  const fetcheditApiData = async () => {
    let quarypara = '';
    const typeofwork = 'edit';
    {
      divison == "edittopic" ?
        quarypara = 'topics_id' :
        divison == "editchapter" ?
          quarypara = 'chapter_id' :
          divison == "editsection" ?
            quarypara = 'section_id' : quarypara = 'question_id'
    }
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await fetch(window.curd_service + `?key_params=${quarypara}&type=${typeofwork}&id=${id}`, settings);
      const apidata = await res.json();
      const fdata = apidata.data;
      setGetIdBasedData(fdata);
      {
        divison == "edittopic" ?
          (document.getElementById('querryname').value = fdata[0].name, setContent(fdata[0].descs)) :
          divison == "editchapter" ?
            (document.getElementById('querryname').value = fdata[0].name, setContent(fdata[0].descs)) :
            divison == "editsection" ?
              (document.getElementById('querryname').value = fdata[0].name, setContent(fdata[0].descs)) :
              (document.getElementById('querryname').value = fdata[0].question_name, document.getElementById('querrydescanswer').innerHTML = getIdBasedData[0]?.answer)
      }
    } catch (error) {
      console.log(error);
    }
  }

  // get data of parent

  const fetchedparentApiData = async () => {
    let parentquerrydata = '';
    const typeofwork = "select"
    {
      divison == "insert_into_chapter" || divison == "editchapter" ?
        parentquerrydata = 'topics' :
        divison == "insert_into_section" || divison == "editsection" ?
          parentquerrydata = 'chapter' : parentquerrydata = 'section'
    }
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await fetch(window.curd_service + `?key_params=${parentquerrydata}&type=${typeofwork}`, settings);
      const apidata = await res.json();
      const fdata = apidata.data;
      setGetParentBasedData(fdata?.map((i) => i));
      editorelem.current = fdata[0].descs
    } catch (error) {
      console.log(error);
    }
  }

  // using of hooks for getting data

  useEffect(() => {
    { id != "0" ? (fetcheditApiData(), fetchedparentApiData()) : (fetchedparentApiData()) };
  }, []);

  // getting parent

  const handleChangeselect = (e) => {
    const parentid = e.target.value;
    setParent(parentid);
  }

  // upload image

  const uploadimage = async () => {
    const imageupload = window.harestec_service + 'image_upload';
    const formdata = new FormData();
    console.log(image)
    formdata.append('file', image, image.name);
    formdata.append('name', 'demo');
    console.log(formdata);
    let response = await axios.post(imageupload, formdata);
    console.log(response);
  }

  const handleimage = (e) => {
    const getimg = e.target.files[0];
    console.log(getimg);
    setImage(getimg);
  }


  // get all image

  const imageapi = window.harestec_service + "getimg";
  const getimageapi = async () => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await fetch( imageapi , settings );
      const apidata = await res.json();
      const fdata = apidata.data;
      setAllImage(fdata?.map((i) => i));
      const imagediv = document.getElementById('imagediv');
      let str = '';
      fdata?.forEach((elem) =>{
        str += `<div className="App" style={styles.App2}>
        <img alt = "gallery image" src="https://harvestec.in/public/image/${elem.image}"></img>
      </div>`
      })
      imagediv.innerHTML = str;
    } catch (error) {
      console.log(error);
    }
  }

  // using of hooks for getting data

  useEffect(() => {
    console.log("run line num 251");
    getimageapi();
    { id != "0" ? (fetcheditApiData(), fetchedparentApiData()) : (fetchedparentApiData()) };
  }, []);

  // rendering start
console.log(getAllImage);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDTypography variant="h4" fontWeight="medium" color="dark" mt={1} my={2}>
        Package:
      </MDTypography>
      <MDBox mb={2}>
        <MDInput type="text" label="Package name" fullWidth id="querryname" />
      </MDBox>
      <MDBox mb={2}>
        <MDInput type="text" label="Description" fullWidth id="descriptionshort" />
      </MDBox>
      <MDBox mb={2}>
        <MDInput type="text" label="charges" fullWidth id="amount" />
      </MDBox>
      <MDBox mb={2}>
        <form className="search-wrapper cf" style={{ display: "flex" }}>
          <MDInput type="file" label="" fullWidth onChange={handleimage} name="file" style={{ Width: "80%" }} />
          <Button type="submit" onClick={(event) => { event.preventDefault(); uploadimage(); }} style={{ marginLeft: "10px", color: "#ffffff", float: "right", marginRight: "20px", marginBottom: "30px", backgroundColor: "#3bad21" }}>Upload</Button>
        </form>
      </MDBox>
      <Button variant="contained" onClick={onsubmitdata} style={{ color: "#ffffff", float: "right", marginRight: "20px", marginBottom: "25px", backgroundColor: "#3bad21" }}>Action</Button>
      

      <Footer />
    </DashboardLayout>
  );
}

export default Package;







// const uploadimage = async (event) => {
//   event.preventDefault();
//   const imageupload = 'http://localhost:3000/public'
//   const formdata = new FormData();
//   console.log(image)
//   formdata.append('myFile', image, image.name);
//   formdata.append('name', 'demo');
//   console.log(formdata);
//   let response = await axios.post(imageupload, formdata);
//   console.log(response);
// }
// const state = {
//   file: null,
//   base64URL: ""
// };

// const getBase64 = file => {
//   return new Promise(resolve => {
//     let fileInfo;
//     let baseURL = "";
//     // Make new FileReader
//     let reader = new FileReader();

//     // Convert the file to base64 text
//     reader.readAsDataURL(file);

//     // on reader load somthing...
//     reader.onload = () => {
//       // Make a fileInfo Object
//       console.log("Called", reader);
//       baseURL = reader.result;
//       console.log(baseURL);
//       setBaseUrl(baseURL);
//       resolve(baseURL);
//     };
//     console.log(fileInfo);
//   });
// };
// function getImage(e) {
//   console.log("changed");
//   console.log(e.target.files[0]);
//   setImage(e.target.files[0])
//   let { file } = state;

//   file = e.target.files[0];

//   getBase64(file)
//     .then(result => {
//       file["base64"] = result;
//       console.log("File Is", file);
//       this.setState({
//         base64URL: result,
//         file
//       });
//     })
//     .catch(err => {
//       console.log(err);
//     });
// }
// const fetchTopicApiData = async (url) => {
//   const settings = {
//     method: "POST",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//     },
//   };
//   try {
//     const res = await fetch(url, settings);
//     const apidata = await res.json();
//     const fdata = apidata.data;
//     setTopicData(fdata);
//   } catch (error) {
//     console.log(error);
//   }
// }
// useEffect(() => {
//   fetchTopicApiData(topicapi);
// }, []);
// console.log(topicdata)
// const handleChange = (e) => {
//   const topic_id = e.target.value;
//   setAge(topic_id);
// }
// const [editorState, setEditorState] = useState(
//   () => EditorState.createEmpty(),
// );