import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import axios from "axios";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDInput from "components/MDInput";
import { Audio } from 'react-loader-spinner';
import { sha1 } from "crypto-hash";

const styles = {
  Appheader: {
    backgroundColor: "#ffffff",
    maxHeight: "2vh",
    display: "flex",
    flexDirection: "column",
    fontSize: "calc(10px + 1vmin)",
    color: "black",
    marginBottom: "5vh",
    textAlign: "center",
    padding: "12px",
  },
  App1: {
    backgroundColor: "#ffffff",
    height: "190px",
    display: "flex",
    flexDirection: "column",
    fontSize: "calc(10px + 1vmin)",
    color: "black",
    marginBottom: "5vh",
    textAlign: "center",
    padding: "50px",
    width: "100%",
    margin: "3% 0 3% 0",
  },
  App: {
    minHeight: "53vh",
    backgroundColor: "#ffffff",
  },
  App2: {
    height: "150px",
    width: "150px",
    margin: "20px"
  }
};



function Edituser() {
  const queryParameters = new URLSearchParams(window.location.search);
  const type = queryParameters.get("type");
  const id = queryParameters.get("id");
  const [getroleBasedData, setGetroleBasedData] = useState([]);
  const [getRole, setRole] = useState("");
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const [gethash, setHash] = useState('');
  const [getIdBasedData, setIdBasedData] = useState();


  // workinf on work base api

  const fetchApiData = async (url) => {
    const querryname = document.getElementById("querryname").value;
    const email = document.getElementById("email").value;
    const phone = document.getElementById("phone").value;
    const password = document.getElementById("password").value;
    const admindata = localStorage.getItem('users')
    const admin_user = JSON.parse(admindata);
    const user_id = admin_user[0].id;
    let data = {};
    id == 0 ? 
     data = {
      "username": querryname,
      "email": email,
      "phone": phone,
      "role": getRole,
      "image_name": image.name,
      "added_by": user_id,
      "password" : gethash
    }: data = {
      "username": querryname,
      "email": email,
      "phone": phone,
      "role": getRole,
    }
    console.log(data);
    const settings = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      if(querryname != "" || email != "" || phone != "" || getRole != ""){
        document.getElementById('requiredparams').style.display = "none"
        setLoading(true);
        const res = await fetch(url, settings);
        await res.json();
        window.location.replace(window.urlmain + `${type}`);
        setLoading(false);
      }else{
        document.getElementById('requiredparams').style.display = "block"
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // calling add user function

  function onsubmitdata() {
   id == 0 ? adddata() : editdata();
  }

  // add user

  function adddata(){
    const api = window.harestec_service + `add_user_API`;
    console.log(api);
    fetchApiData(api);
  }

  // edit user

  function editdata(){
    const api = window.harestec_service + `update_user/${id}`;
    fetchApiData(api);
  }

  // get all role

  const fetchedroleApiData = async () => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await fetch(window.curd_service + `?key_params=get_user_type&type=select`, settings);
      const apidata = await res.json();
      const fdata = apidata.data;
      setGetroleBasedData(fdata?.map((i) => i));
    } catch (error) {
      console.log(error);
    }
  }

  // get data by id 

  const fetchdataonthebaseofid = async () => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await fetch(window.harestec_service + `?User_data?user_id=${id}`, settings);
      const apidata = await res.json();
      const fdata = apidata.data;
      setIdBasedData(fdata?.map((i) => i));
      document.getElementById('querryname').value = fdata[0].name;
      document.getElementById('email').value = fdata[0].email;
      document.getElementById('phone').value = fdata[0].phone;
    } catch (error) {
      console.log(error);
    }
  }

  // using of hooks for getting data

  useEffect(() => {
   id == 0 ? fetchedroleApiData() : fetchedroleApiData(); fetchdataonthebaseofid()
  }, []);

  // getting parent

  const handleChangeselect = (e) => {
    const role = e.target.value;
    setRole(role);
  }

  // upload image

  const uploadimage = async () => {
    setLoading(true);
    const imageupload = window.harestec_service + 'image_upload';
    const formdata = new FormData();
    console.log(image)
    formdata.append('file', image, image.name);
    formdata.append('name', 'demo');
    console.log(formdata);
    let response = await axios.post(imageupload, formdata);
    console.log(response);
    setLoading(false);
  }

  const handleimage = (e) => {
    const getimg = e.target.files[0];
    setImage(getimg);
  }

  const handlepassword = (e) =>{
    const password = e.target.value;
    const hashPass = async () => {
      let hashpassword = await sha1(password);
      setHash(hashpassword);
    }
    hashPass();
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDTypography variant="h4" fontWeight="medium" color="dark" mt={1} my={2}>
        Add User
      </MDTypography>
      <MDBox mb={2}>
        <MDInput type="text" label="Name" fullWidth id="querryname" required/>
      </MDBox>
      <MDBox mb={2}>
        <MDInput type="text" label="email" fullWidth id="email" required/>
      </MDBox>
      <MDBox mb={2}>
        <MDInput type="password" onChange={handlepassword} label="password" fullWidth id="password" required/>
      </MDBox>
      <MDBox mb={2}>
        <MDInput type="number" label="Phone" fullWidth id="phone" required/>
      </MDBox>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">
          Select role
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          value={getRole}
          label="Age"
          onChange={handleChangeselect}
          style={{ height: "45px", marginBottom: "15px" }}
          required
        >
          {getroleBasedData.map((e, index) => (
            <MenuItem key={index} value={e.id}>{e.user_type_name}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <MDBox mb={2}>
        <form className="search-wrapper cf" style={{ display: "flex" }}>
          <MDInput type="file" label="" fullWidth onChange={handleimage} name="file" style={{ Width: "80%" }} required/>
          <Button type="submit" onClick={(event) => { event.preventDefault(); uploadimage(); }} style={{ marginLeft: "10px", color: "#ffffff", float: "right", marginRight: "20px", marginBottom: "30px", backgroundColor: "#3bad21" }}>Upload</Button>
        </form>
      </MDBox>
      <MDTypography variant="h6" fontWeight="medium" color="dark" mt={1} my={2} style={{color: "red", display: "none"}} id="requiredparams">
        All fields are required
      </MDTypography>
      {loading == true ? <Audio
        left = "90"
        height="80"
        width="80"
        radius="9"
        color="green"
        ariaLabel="loading"
        wrapperStyle
        wrapperClass
      /> : <Button variant="contained" onClick={onsubmitdata} style={{ color: "#ffffff", float: "right", marginRight: "20px", marginBottom: "25px", backgroundColor: "#3bad21" }}>Submit</Button>}
      <Footer />
    </DashboardLayout>
  );
}

export default Edituser;






