import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import axios from "axios";
import Mybook from "components/MyBook"

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDInput from "components/MDInput";

function Mytransaction() {

    const [data, setApiData] = useState();
    const resourcesforudiv = { width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" };

    const fetchApiData = async () => {
        const data = {
            "id": JSON.parse(localStorage.getItem('client'))[0].id
        }
        const settings = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
        };
        try {
            const res = await fetch(window.harestec_service + "allmytransaction", settings);
            const apidata = await res.json();
            const fdata = apidata.data;
            setApiData(fdata?.map((i) => i));
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchApiData();
    }, []);

    function view_book(id) {
        window.open(`/mybooks?id=${id}`);
    }

    return (
        <>
            <Mybook />
            <MDBox m={2} style={{ width: '96%' }} id="packages">
                <MDBox style={{ width: '100%', display: "flex", justifyContent: "center", alignItems: "center", }}>
                    <MDBox style={resourcesforudiv}>
                        <MDBox style={{ width: "100%", height: "80%", }}>
                            <MDBox style={{ width: "100%", display: "block", alignItems: "center", justifyContent: "center" }}>
                                {data?.map((e, index) => (
                                    <MDBox my={2} style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#fff" }}>
                                        <MDBox style={{ width: "35%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <img src={window.photourl + 'image/' + e.image_name} style={{ width: "244.53", height: "330px", border: "1px solid #D8D8D8" }} />
                                        </MDBox>
                                        <MDBox style={{ width: "65%", height: "100%" }}>
                                            <MDTypography mx={2} mt={1} style={{ fontSize: "18px", fontWeight: "600", color: "black", lineHeight: "38.73px" }}>
                                                {e.name}
                                            </MDTypography>
                                            <MDTypography mx={2} my={2} style={{ fontSize: "14px", fontWeight: "400", lineHeight: "23px", color: "#433C3C" }}>
                                                {e.descshort}
                                            </MDTypography>
                                            <MDBox mx={2} my={3} style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                                                <MDBox style={{ display: "flex", justifyContent: "start", alignItems: "start", width: "30%" }}>
                                                    <MDBox px={3} py={1} style={{ display: "flex", justifyContent: "start", alignItems: "center", borderRadius: "40px", backgroundColor: "#2B5F47", color: "#fff" }}>
                                                        <MDTypography style={{ fontSize: "16px", fontWeight: "500", lineHeight: "23px", color: "#fff", cursor: "pointer" }} onClick={(event) => { event.preventDefault(); view_book(e.id) }}>View</MDTypography>
                                                    </MDBox>
                                                </MDBox>
                                            </MDBox>
                                        </MDBox>
                                    </MDBox>

                                ))}
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </MDBox>
        </>
    )
}

export default Mytransaction;