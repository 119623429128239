import * as React from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function FAQ() {
    AOS.init({
        duration: 1200,
    })

    const [dataf, setApiData] = useState();
    const [show, setShow] = useState('N');
    const [indexanswer, setIndexanswer] = useState();
    const api = window.curd_service + "?key_params=questions&type=select";
    const fetchApiData = async (url) => {
        const settings = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        };
        try {
            const res = await fetch(url, settings);
            const apidata = await res.json();
            const fdata = apidata.data;
            console.log(fdata, 'line no 22')
            setApiData(fdata?.map((i) => i));
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchApiData(api);
    }, []);

    const toggleaccordian = (index) => {
        setShow('Y');
        setIndexanswer(index);
        document.getElementById(`mainanswer${index}`).style.display = "flex";
        console.log('open');
    }

    const toggleaccordianclose =(index) => {
        setShow('N');
        setIndexanswer(index);
        document.getElementById(`mainanswer${index}`).style.display = "none";
        console.log('close');
    }


    return (
        <>
            <MDBox pb={6} style={{ width: "100%", backgroundColor: "#FDF9F5", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <MDBox style={{ width: "80%" }}>
                    <MDTypography my={2} style={{ fontSize: "18px", fontWeight: "600", lineHeight: "38.73px", color: "#1E1B1B", fontFamily: "Inter"  }}>
                        Frequently Asked Questions
                    </MDTypography>
                    <MDBox style={{ width: "100%" }}>
                        {dataf?.map((e, index) => (
                            <MDBox data-aos="fade-right" my={1} style={{ width: "100%", backgroundColor: "#FFFFFF", border: "0.5px solid #997B64", borderRadius: "10px" }}>
                                <MDBox py={1} style={{ display: "flex", justifyContent: "center", alignItems: "center", borderBottom: "0.5px solid #997B64" }}>
                                    <MDBox style={{ width: "90%", height: "100%", display: "flex", justifyContent: "start", alignItems: "center" }}>
                                        <MDTypography mx={3} style={{ fontSize: "14px", fontWeight: "500", lineHeight: "24.2px", color: "#1E1B1B", fontFamily: "Inter"  }}>
                                            {e.name}
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox style={{ width: "10%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", fontFamily: "Inter"  }}>
                                        {show == 'Y' && indexanswer == index ? <img style={{cursor: "pointer"}} src='/Groupc.png' onClick={(e) => { e.preventDefault(); toggleaccordianclose(index) }} /> : <img style={{cursor: "pointer"}} src='/Group.png' onClick={(e) => { e.preventDefault(); toggleaccordian(index) }}/>}
                                    </MDBox>
                                </MDBox>
                                <MDBox id={'mainanswer' + index} style={{ width: "100%", height: "100%", display: "none", justifyContent: "center", alignItems: "center" }}>
                                    <MDBox style={{ width: "90%", height: "100%", display: "flex", justifyContent: "start", alignItems: "center" }}>
                                        <MDTypography my={2} style={{ fontSize: "12px", fontWeight: "400", lineHeight: "28px", color: "#1E1B1B", fontFamily: "Inter" }}>
                                            {e.answer}
                                        </MDTypography>
                                    </MDBox>
                                </MDBox>
                            </MDBox>
                        ))}
                    </MDBox>
                </MDBox>
            </MDBox>
        </>
    )
}

export default FAQ;