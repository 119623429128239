import { useState } from "react";
import * as React from 'react';
import MDBox from "components/MDBox";
import "./index.css"
import 'bootstrap/dist/css/bootstrap.css';
import Navbar from 'components/Navbar';
import MainLandingBlock from 'components/MainLandingBlock';
import { useMediaQuery } from 'react-responsive'
import Resource from 'components/Resource';
import AboutAuthor from 'components/AboutAuthor';
import Testimonial from 'components/Testimonial';
import Services from 'components/Services';
import FAQ from 'components/FAQ';
import Footercus from 'components/Footercus';
import Modalbs from 'components/Modalbs';
import Modalbssignin from 'components/Modalbssignin';
import Videocallassitance from 'components/Videocallassitance';

function Client() {
  const [showmodal, setShowmodal] = useState();
  const [showmodalsign, setShowmodalsign] = useState('');
  const handlemodalchangefun = (data) => {
    setShowmodal(data.login);
    setShowmodalsign(data.signup)
  }

  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})

  

  return (
    <>
      <MDBox style={{ position: "absloute", background: '#FDF9F5' }}>
        <Navbar getmodalchangestats={handlemodalchangefun}/>
        <MainLandingBlock />
        <Resource />
        <Videocallassitance />
        <Services />
        <AboutAuthor />
        <Testimonial />
        <FAQ />
        <Footercus />
      </MDBox>
      <MDBox style={{display: `${showmodal != 'show' ? 'none' : 'block'}`}}>
        <Modalbs changestatemodal={handlemodalchangefun}/>
      </MDBox>
      <MDBox style={{display: `${showmodalsign != 'show' ? 'none' : 'block'}`}}>
        <Modalbssignin changestatemodal={handlemodalchangefun}/>
      </MDBox>
    </>
  );
}

export default Client;


