import * as React from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Navbar from 'components/Navbar';
import Footercus from 'components/Footercus';
import image from './formbg.jpg';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState, useRef } from "react";

export default function ServiceForm() {
    const [getParent, setParent] = useState("");
    const handleChangeselect = (e) => {
        const parentid = e.target.value;
        setParent(parentid);
    }

    const submitform = async () =>{
        const firstnamedata = document.getElementById('firstnamedata').value
        const lastnamedata = document.getElementById('lastnamedata').value
        const emaildata = document.getElementById('emaildata').value
        const data = {
            'email' : emaildata,
            'first_name' : firstnamedata,
            'last_name' : lastnamedata,
            'query' : getParent,
        }
        const settings = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",
            },
          };
    
    
          try {
            const res = await fetch(url, settings);
            window.location.reload();
          }catch(error){
            console.log(error);
          }
    }
    return (
        <>
            <Navbar />
            <MDBox style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh", background: `url(${image})`, backgroundRepeat: "no-repeat", backgroundSize: "100% 100%" }}>
                <MDBox style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "50%" }}>
                    <MDBox>
                        <MDBox style={{ width: "100%", borderRadius: "12px", display: "flex", alignItems: "center", justifyContent: "center", background: "#fff" }}>
                            <MDBox style={{ width: "100%" }}>
                                <MDBox style={{ width: "100%", borderRadius: "12px", display: "flex", alignItems: "center", justifyContent: "center", }}>
                                    <MDTypography style={{ color: "brown", fontSize: "23px", fontWeight: "700", lineHeight: "23px" }} my={2}>ENQUIRY FORM</MDTypography>
                                </MDBox>
                                <MDBox style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <MDBox style={{ width: "90%" }}>
                                        <MDBox my={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <MDInput type="text" label="First Name" fullWidth id="firstnamedata" required style={{ width: "50%", margin: '0 0.5rem 0 0' }} />
                                            <MDInput type="text" label="Last Name" fullWidth id="lastnamedata" required style={{ width: "50%", margin: '0 0 0 0.5rem' }} />
                                        </MDBox>
                                        <MDInput type="email" label="Email Address" fullWidth id="emaildata" required style={{ margin: "0.5rem 0" }} />
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">
                                                Select service
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                value={getParent}
                                                label="Age"
                                                onChange={handleChangeselect}
                                                style={{ height: "45px", marginBottom: "15px" }}
                                            >
                                                <MenuItem value="On-Site Visit">On-Site Visit</MenuItem>
                                                <MenuItem value="LAB TESTING">LAB TESTING</MenuItem>
                                                <MenuItem value="PILOT TESTING">PILOT TESTING</MenuItem>
                                                <MenuItem value="COMMERCIAL SCALE MODELLING">COMMERCIAL SCALE MODELLING</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </MDBox>
                                </MDBox>
                                <MDBox style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", margin: "1rem 0" }}>
                                    <MDBox px={3} py={1} style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "40px", backgroundColor: "brown", color: "#fff" }} >
                                        <MDTypography style={{ fontSize: "16px", fontWeight: "500", lineHeight: "23px", color: "#fff", cursor: "pointer" }} onClick={submitform}>SUBMIT</MDTypography>
                                    </MDBox>
                                </MDBox>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </MDBox>
            <Footercus />
        </>
    )
}