import Button from "react-bootstrap/Button";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useState, useEffect } from "react";
import useRazorpay from "react-razorpay";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
import { SwapCalls } from "@mui/icons-material";

function Det() {
  const Razorpay = useRazorpay();
  const queryParameters = new URLSearchParams(window.location.search);
  const previouspage = window.location.pathname;
  const previouspageseg = previouspage.split('?');
  const finalprevious = 'http://localhost:3000' + previouspageseg[0];
  console.log(finalprevious);
  const id = queryParameters.get("id");
  const [dataf, setApiData] = useState();
  const [getTopicName, setTopicName] = useState('');
  const [getTopicdesc, setTopicdesc] = useState('');
  const [getTopicimage, setTopicimage] = useState('');
  const [getTopicId, setTopicid] = useState('');
  const [getTopicAmount, setTopicAmount] = useState('');
  const api = window.harestec_service + `gettopictoChapterDataAPI?topic_id=${id}`;
  console.log(api);
  const fetchApiData = async (url) => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await fetch(url, settings);
      const apidata = await res.json();
      const fdata = apidata.data;
      setTopicName(fdata[0].topic_name);
      setTopicdesc(fdata[0].topic_descshort);
      setTopicimage(fdata[0].topic_image_name);
      setTopicid(fdata[0].parent_id);
      setTopicAmount(fdata[0].topic_amount)
      setApiData(fdata?.map((i) => i));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchApiData(api);
  }, []);

  // payment gateway resp function

  const respfunction = async (id, payment_id, status, msg) => {
    const data = {
      "pay_id" : payment_id,
      "status" : status,
      "error_msg" : msg
    }
    const settings = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    const api = window.harestec_service + `updateuser_paymt_txnsAPI/${id}`;
    await fetch(api, settings)
  }

  // main payment gateway

  const buy = async (id, amount, type) => {
    if (localStorage.getItem('client') == null) {
      const login_type = "buy_login"
      window.location.replace(window.urlmain + `authentication/sign-inclient?id=${id}&amount=${amount}&type=${type}&login_type=${login_type}&previous=${finalprevious}`)
    } else {
      const userlogin = localStorage.getItem('client');
      const userlogindata = JSON.parse(userlogin)
      const user_id = userlogindata[0].id;
      const fdataarray = amount.split('/');
      console.log(fdataarray[0])
      const famtarray = fdataarray[0].split(',')
      const amtstring = famtarray[0]+famtarray[1];
      console.log(amtstring);
      const famount = Number(amtstring) * 100;
      console.log(famount)
      const data = {
        "user_id": user_id,
        "type_id": id,
        "amt": amount,
        "type": type
      }

      const settings = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      const api = window.harestec_service + 'adduser_paymt_txnsAPI';
      const res = await fetch(api, settings)
      const fdata = await res.json();
      console.log(fdata)
      const fdataid = fdata.data[0].id;
      console.log(fdataid)
      const reciept = 'receipt_order_' + id + 'cli' + user_id;
      console.log(reciept)
      if (fdata.message == "Added Successfully") {
        const options = {
          key: "rzp_live_hmiII6CslKr36A", // Enter the Key ID generated from the Dashboard
          amount: famount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: "INR",
          name: "Proeffico solutions private limited",
          description: "Test Transaction",
          image: "logo.jpg",
          receipt: reciept, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
          handler: function (response) {
            alert(response.razorpay_payment_id);
            alert(response.razorpay_order_id);
            alert(response.razorpay_signature);
            respfunction(fdataid, response.razorpay_payment_id, 'APPROVED', response.razorpay_order_id)
          },
          prefill: {
            name: "Piyush Garg",
            email: "youremail@example.com",
            contact: "9999999999",
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };

        const rzp1 = new Razorpay(options);

        rzp1.on("payment.failed", function (response) {
          alert(response.error.code);
          alert(response.error.description);
          alert(response.error.source);
          alert(response.error.step);
          alert(response.error.reason);
          alert(response.error.metadata.order_id);
          alert(response.error.metadata.payment_id);
          respfunction(fdataid, response.error.metadata.payment_id, 'DECLINED', response.error.description)
        });

        rzp1.open();
      }
    }
  }

  return (
    <div style={{ width: "100%" }}>
      <div className="mx-5 my-3">
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "50%" }}>
            <img alt="chap_name" src={'https://harvestec.in/image/' + getTopicimage} style={{ width: "100%", height: "300px", borderRadius: "12px" }} />
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "50%" }}>
            <Button variant="primary" onClick={() => buy(getTopicId, getTopicAmount, 'topic')}>Buy whole Topic</Button>
          </div>
        </div>

        <MDTypography className="my-3" variant="h1" color="dark" style={{ fontSize: "24px" }}>
          {getTopicName}
        </MDTypography>
        <MDTypography className="my-3" variant="p" color="dark" style={{fontSize: "13px", fontWeight: "400"}}>
          {getTopicdesc}
        </MDTypography>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", maxWidth: "1200px" }}>
          <Row xs={1} md={3} className="g-3">
            {dataf?.map((ele) => (
              <Col className=' cuastcol d-flex' style={{ justifyContent: "center" }}>
                <Card key={ele.id} className='mx-3 my-2' style={{ minWidth: '18rem', maxWidth: '18rem', maxHeight: "500px" }}>
                  <Card.Img variant="top" src={'https://harvestec.in/image/' + ele.image_name} style={{height: "150px"}}/>
                  <Card.Body>
                    <Card.Title>{ele.name}</Card.Title>
                    <Card.Text style={{fontSize: "13px", fontWeight: "400"}}>
                      {ele.descshort.substring(0, 100)}
                    </Card.Text>
                    <Card.Text>
                      {ele.amount}
                    </Card.Text>
                    <Button variant="primary" onClick={() => buy(ele.id, ele.amount, 'chap')}>Buy</Button>
                    <div className="my-2" style={{ display: "flex", width: "18rem" }} >
                      <Card.Text onClick={(e) => { e.preventDefault(); e.stopPropagation(); addfavorcart(ele.id, 'chapter', 'cart') }} className="w-20 text-info">
                        <ShoppingCartIcon />
                      </Card.Text>
                      <Card.Text onClick={(e) => { e.preventDefault(); e.stopPropagation(); addfavorcart(ele.id, 'chapter', 'fav') }} className="w-20" style={{color: "red"}}>
                        <FavoriteBorderIcon />
                      </Card.Text>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Det;
