import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import { useEffect, useState } from "react";

export default function data() {
  const [dataf, setApiData] = useState();
  let fdataobj = {};
  const fdataarray = [];
  const api = window.harestec_service + "getUserQueryAreasAPI";
  const fetchApiData = async (url) => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await fetch(url, settings);
      const apidata = await res.json();
      const fdata = apidata.data;
      setApiData(fdata?.map((i) => i));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchApiData(api);
  }, []);

  dataf?.forEach((element, index) => {
    fdataobj = {
      Slno: (
        <MDBox ml={-1}>
          <MDBadge badgeContent={index + 1} color="success" variant="gradient" size="sm" />
        </MDBox>
      ),
      Name: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {element.username}
        </MDTypography>
      ),
      email: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {element.email}
        </MDTypography>
      ),
      phone: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {element.phone}
        </MDTypography>
      ),
      type: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {element.type}
        </MDTypography>
      ),
      time: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {element.viewed_on}
        </MDTypography>
      )
    };
    fdataarray.push(fdataobj);
  });
  return {
    columns: [
      { Header: "Sl.no", accessor: "Slno", align: "center" },
      { Header: "Email", accessor: "email", align: "center" },
      { Header: "Phone", accessor: "phone", align: "center" },
      { Header: "Type", accessor: "type", align: "center" },
      { Header: "Time", accessor: "time", align: "center" },
    ],
    rows: fdataarray,
  };
}
