import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState, useEffect } from "react";
import { sha1 } from "crypto-hash"
import { GoogleLogin } from '@react-oauth/google';
import MDInput from "components/MDInput";
import jwtDecode from "jwt-decode";



function Modalbs({ changestatemodal }) {
  const [show, setShow] = useState('hide');
  const access_token = "674231015924-urhfeo9chi4cr37icj4p96r1tbu4ufhq.apps.googleusercontent.com"
  const [getApiData, setApiData] = useState();
  const changemodalfunction = () => {
    show == 'show' ? setShow('hide') : setShow('show');
    changestatemodal(show);
  }

  const activitydata = async () => {
    const email = document.getElementById('inputemail').value;
    const apihandler = window.harestec_service + 'adduser_query_areasAPI'
    const data = {
      "email": email,
      "type": "login"
    }
    const settings = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await fetch(apihandler, settings);
  }

  const fetchApiData = async (url) => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      localStorage.clear();
      const res = await fetch(url, settings);
      const apidata = await res.json();
      const fdata = apidata.list;
      setApiData(fdata?.map((i) => i));
      localStorage.setItem('client', JSON.stringify(fdata?.map((i) => i)));
      const clientdatastr = localStorage.getItem('client');
      if (clientdatastr != 'undefined') {
        const clientdata = JSON.parse(clientdatastr);
        activitydata();
        if (clientdata[0].user_type_id == '3') {
          window.open('/resources', '_self');
        } else {
          document.getElementById('errormsg').style.display = "flex"
        }
      } else {
        document.getElementById('errormsg').style.display = "flex"
      }
    } catch (error) {
      console.log(error);
    }
  };


  function signIn() {
    const email = document.getElementById('inputemail').value;
    const cpassword = document.getElementById('inputpassword').value;
    const hashPass = async () => {
      const password = await sha1(cpassword)
      const api = window.harestec_service + `userLogin?email=${email}&password=${password}`;
      fetchApiData(api);
    }
    hashPass();
  }

  const responseMessage = async (response) => {
    console.log(response, 'line no 85');
    const [headerBase64, payloadBase64] = response.credential.split('.');
    const decodedHeader = JSON.parse(atob(headerBase64));
    const decodedPayload = JSON.parse(atob(payloadBase64));
    console.log(decodedHeader, decodedPayload, 'line no 88');
    const userdata = {
      "username": decodedPayload.name,
      "email": decodedPayload.email,
      "email_verfied": decodedPayload.email_verified,
      "user_type_id": 3,
      "type": "google"
    }
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userdata)
    };
    try {
      localStorage.clear();
      const adduserapi = window.harestec_service + 'add_user_API'
      const res = await fetch(adduserapi, settings);
      const apidata = await res.json();
      const fdata = apidata.list;
      setApiData(fdata?.map((i) => i));
      localStorage.setItem('client', JSON.stringify(fdata?.map((i) => i)));
      const clientdatastr = localStorage.getItem('client');
      if (clientdatastr != 'undefined') {
        const clientdata = JSON.parse(clientdatastr);
        activitydata();
        if (clientdata[0].user_type_id == '3') {
          window.open('/resources', '_self');
        } else {
          document.getElementById('errormsg').style.display = "flex"
        }
      } else {
        document.getElementById('errormsg').style.display = "flex"
      }
    } catch (error) {
      console.log(error);
    }
  };

  const errorMessage = (error) => {
    console.log(error);
  };

  return (
    <div
      className="modal show"
      style={{ display: 'block', position: 'fixed' }}
    >
      <Modal.Dialog style={{ color: "#fff" }}>
        <Modal.Header closeButton onClick={changemodalfunction}>
        </Modal.Header>
        <MDBox mx={1} my={2} style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
          <MDTypography style={{ height: "23px", fontSize: '16px', fontWeight: "400", lineHeight: "23px", color: "#433C3C" }}>Log in to harvestec?</MDTypography>
          <MDTypography mx={2} style={{ height: "23px", fontSize: '16px', fontWeight: "400", lineHeight: "23px", color: "#433C3C" }}>Sign In</MDTypography>
        </MDBox>
        <MDBox style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
          <MDBox style={{ width: "80%" }}>
            <MDBox my={1} style={{ display: "flex", justifyContent: "start", alignItems: "center", width: "100%" }}>
              <MDTypography style={{ fontSize: '20px', fontWeight: "500", lineHeight: "24.2px", color: "#1E1B1B" }}>Log in to your account</MDTypography>
            </MDBox>
            <MDBox my={1} style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
              <MDInput type="email" label="Email" fullWidth id="inputemail" required />
            </MDBox>
            <MDBox my={1} style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
              <MDInput type="password" label="password" fullWidth id="inputpassword" required />
            </MDBox>
            <MDBox my={1} id="errormsg" style={{ display: "none", justifyContent: "center", alignItems: "center", width: "100%", height: "40px" }}>
              <MDTypography style={{ fontSize: '16px', fontWeight: "500", lineHeight: "23px", color: "red" }}>Invalid credential !</MDTypography>
            </MDBox>
            <MDBox style={{ display: "flex", justifyContent: "center", width: "100%"}}>
              <MDBox my={1} style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "40px", borderRadius: "48px", backgroundColor: "#2B5F47", color: "#fff" }}>
                <MDTypography mx={2} style={{ fontSize: '14px', fontWeight: "500", lineHeight: "20px", color: "#fff", cursor: "pointer" }} onClick={(e) => { e.preventDefault(); signIn(); }}>Log in with email</MDTypography>
              </MDBox>
              <MDBox my={1} mx={2} style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px" }}>
                <MDTypography style={{ fontSize: '16px', fontWeight: "500", lineHeight: "23px", color: "black" }}>or</MDTypography>
              </MDBox>
              <MDBox my={1} mb={3} style={{ width: "100%", height: "40px" }}>
                <GoogleLogin style={{ width: '100%', border: "0.5px solid #C7C7C7", borderRadius: "48px" }} cookiePolicy={'single_host_origin'} buttonText="Sign in with Google" onSuccess={responseMessage} onError={errorMessage} />
                {/* <img src='/google.png' />
              <MDTypography mx={2} style={{fontSize: '16px', fontWeight: "500", lineHeight: "23px", color: "black" }} onSuccess={responseMessage} onError={errorMessage}>Login with Google</MDTypography> */}
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      </Modal.Dialog>
    </div>
  );
}

export default Modalbs;