import { useState } from "react";
import { sha1 } from "crypto-hash";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images

import bgImage from "assets/images/1463518.jpg";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [getApiData, setApiData] = useState();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const fetchApiData = async (url) => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      localStorage.clear();
      const res = await fetch(url, settings);
      const apidata = await res.json();
      const fdata = apidata.list;
      setApiData(fdata?.map((i) => i));
      localStorage.setItem('users', JSON.stringify(fdata?.map((i) => i)));
      const usersdatastr = localStorage.getItem('users');
      if(usersdatastr != "undefined"){
        const userdata = JSON.parse(usersdatastr);
        if (userdata.length == 1) {
          window.location.replace('/dashboard');
          document.getElementById('invalidparameters').style.display = "none"
        } else {
          window.location.replace('/authentication/sign-in');
        }
      }else{
        document.getElementById('invalidparameters').style.display = "block"
      }
    } catch (error) {
      console.log(error);
    }
  };

  function signIn() {
    const email = document.getElementById('inputemail').value;
    const cpassword = document.getElementById('inputpassword').value;
    if(email != "" || cpassword != ""){
      document.getElementById('requiredparameters').style.display = "none"
      const hashPass = async () => {
        const password = await sha1(cpassword)
        const api = window.harestec_service + `userLogin?email=${email}&password=${password}`;
        fetchApiData(api);
      }
      hashPass();
    }else{
      document.getElementById('requiredparameters').style.display = "block"
    }
  }

  const handle = (event) => {
    if (event.keyCode === 13) {
      const email = document.getElementById('inputemail').value;
      const cpassword = document.getElementById('inputpassword').value;
      const hashPass = async () => {
        const password = await sha1(cpassword)
        const api = window.harestec_service + `userLogin?email=${email}&password=${password}`;
        fetchApiData(api);
      }
      hashPass();
    }
  }
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Learning Portal Admin
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="email" label="Email" fullWidth id="inputemail" required/>
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Password" fullWidth id="inputpassword" required/>
            </MDBox>
            <MDTypography variant="h6" fontWeight="medium" color="danger" mt={1} style={{display: "none", color: "red"}} id="invalidparameters">
              Invalid credentials !
            </MDTypography>
            <MDTypography variant="h6" fontWeight="medium" color="danger" mt={1} style={{display: "none", color: "red"}} id="requiredparameters">
              Eamil and password are required !
            </MDTypography>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={signIn} onkeypress={event => handle(event)}>
                sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
