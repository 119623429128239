import { useEffect } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import Dashboard from "layouts/dashboard";
import Topics from "layouts/topics";
import Chapter from "layouts/chapter";
import Section from "layouts/section";
import Questions from "layouts/questions";
import Media from "layouts/media"; 
import Users from "layouts/users";
import Transaction from "layouts/transaction";
import Activity from "layouts/activity" ; 
import Paymentpage from "layouts/paymentpage" ;
import Mybooks from "layouts/mybooks"
import Videocalllist from "layouts/videocalllist"
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import Videocallconfirmation from "layouts/videocallconfirmation"
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  // console.log(localStorage('users'));
  const userdatastr = localStorage.getItem('users');
  const userdata = JSON.parse(userdatastr);
  const user_type = userdata[0].user_type_name;
  const routesadmin = [
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: "/dashboard",
      component: <Dashboard />,
    },
    {
      type: "collapse",
      name: "Topics",
      key: "topics",
      icon: <Icon fontSize="small">topic</Icon>,
      route: "/topics",
      component: <Topics />,
    },
    {
      type: "collapse",
      name: "Chapter",
      key: "chapter",
      icon: <LibraryBooksIcon fontSize="small" />,
      route: "/chapter",
      component: <Chapter />,
    },
    {
      type: "collapse",
      name: "Section",
      key: "section",
      icon: <BeenhereIcon fontSize="small" />,
      route: "/Section",
      component: <Section />,
    },
    {
      type: "collapse",
      name: "Questions",
      key: "questions",
      icon: <QuestionAnswerIcon fontSize="small" />,
      route: "/Questions",
      component: <Questions />,
    },
    {
      type: "collapse",
      name: "Users",
      key: "user",
      icon: <SupervisedUserCircleIcon fontSize="small" />,
      route: "/users",
      component: <Users />,
    },
    {
      type: "collapse",
      name: "Media",
      key: "media",
      icon: <PermMediaIcon fontSize="small" />,
      route: "/media",
      component: <Media />,
    },
    {
      type: "collapse",
      name: "Transaction",
      key: "transaction",
      icon: <ReceiptLongIcon fontSize="small" />,
      route: "/transaction",
      component: <Transaction />,
    },
    {
      type: "collapse",
      name: "Activity",
      key: "activity",
      icon: <LocalActivityIcon fontSize="small" />,
      route: "/activity",
      component: <Activity />,
    },
    {
      type: "collapse",
      name: "Video Appointment",
      key: "videocalllist",
      icon: <VideoCameraBackIcon fontSize="small" />,
      route: "/videocalllist",
      component: <Videocalllist />,
    },
  ];
  const routesnonadmin = [
    {
      type: "collapse",
      name: "Topics",
      key: "topics",
      icon: <Icon fontSize="small">topic</Icon>,
      route: "/topics",
      component: <Topics />,
    },
    {
      type: "collapse",
      name: "Chapter",
      key: "chapter",
      icon: <Icon fontSize="small">topic</Icon>,
      route: "/chapter",
      component: <Chapter />,
    },
    {
      type: "collapse",
      name: "Section",
      key: "section",
      icon: <Icon fontSize="small">topic</Icon>,
      route: "/Section",
      component: <Section />,
    },
    {
      type: "collapse",
      name: "Questions",
      key: "questions",
      icon: <Icon fontSize="small">topic</Icon>,
      route: "/Questions",
      component: <Questions />,
    },
    {
      type: "collapse",
      name: "Paymentpage",
      key: "paymentpage",
      icon: <Icon fontSize="small">login</Icon>,
      route: "/paymentpage",
      component: <Paymentpage />,
    },
    {
      type: "collapse",
      name: "Mybooks",
      key: "mybooks",
      icon: <Icon fontSize="small">login</Icon>,
      route: "/mybooks",
      component: <Mybooks />,
    },
    {
      type: "collapse",
      name: "Video-call-confirmation",
      key: "videocallconfirmation",
      icon: <Icon fontSize="small">login</Icon>,
      route: "/videocallconfirmation",
      component: <Videocallconfirmation />,
    }
  ]
  let finalroutes = new Array;
  {user_type == "Admin" ? (finalroutes = routesadmin):(finalroutes = routesnonadmin)}
  const renderRoutes = finalroutes.map(({ type, name, icon, title, noCollapse, key, href, route }) => {
    let returnValue;

    if (type === "collapse") {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
          <ListItemText
          primary="Logout"
          sx={(theme) =>
            collapseText(theme, {
              miniSidenav,
              transparentSidenav,
              whiteSidenav,
              active,
            })
          }
        />
        </Link>
      ) : (
        <NavLink key={key} to={route}>
          <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
        </NavLink>
      );
    } else if (type === "title") {
      returnValue = (
        <MDTypography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MDTypography>
      );
    } else if (type === "divider") {
      returnValue = (
        <Divider
          key={key}
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    }

    return returnValue;
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <MDBox component="img" src={brand} alt="Brand" width="2rem" />}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              Harvestec
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
