import * as React from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import ResourcesNav from 'components/ResourcesNav';
import { useState, useEffect } from "react";
import { useMediaQuery } from 'react-responsive';
import image from './formbg.jpg';

function ResourcesBody({ data }) {
    const [dataf, setApiData] = useState();
    var url_stringden = document.URL;
    var urldm = new URL(url_stringden)
    let paramas = urldm.searchParams.get('params');
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })


    const resourcesforudiv = { width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" };
    const api = window.harestec_service + `gettopictoChapterDataAPI?topic_id=${data}`
    const fetchApiData = async (url) => {
        console.log(paramas, 'line no 16');
        if (data == 'vc' || paramas == 'vc') {
            vssetup()
        } else {
            console.log(paramas, 'line no 19');
            const settings = {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            };
            try {
                const res = await fetch(url, settings);
                const apidata = await res.json();
                const fdata = apidata.data;
                setApiData(fdata?.map((i) => i));
                document.getElementById('packages').style.display = "block"
                document.getElementById('vcdiv').style.display = "none"
            } catch (error) {
                console.log(error);
            }
        }
    };

    function vssetup() {
        document.getElementById('packages').style.display = "none"
        document.getElementById('vcdiv').style.display = "flex"
    }

    useEffect(() => {
        fetchApiData(api);
    }, [data]);

    const payment_intiate = (id, amount) => {
        const clientdatastr = localStorage.getItem('client');
        console.log(clientdatastr, 'line no 54');
        if (!clientdatastr || clientdatastr == "undefined") {
            window.open(`/authentication/sign-inclient?type=buy&amount=${amount}&id=${id}&typeof=chapter`)
        } else {
            window.open(`/paymentpage?id=${id}&amount=${amount}&type=chapter`, "_self")
        }
    }

    const submitappointment = async () => {
        const clientdatastr = localStorage.getItem('client');
        const mainclientdata = JSON.parse(clientdatastr)
        const userdata = mainclientdata[0].id
        console.log(clientdatastr, 'line no 68');
        const toappointment = document.getElementById('toappointment').value
        const fromappointment = document.getElementById('fromappointment').value
        const comments = document.getElementById('comments').value
        const dateofappointment = document.getElementById('dateofappointment').value
        const apihandler = window.harestec_service + "addAppointmentSlotsAPI";
        const data = {
            'user_id': userdata,
            'date': dateofappointment,
            'time': fromappointment,
            'endtime': toappointment,
            'query': comments,
            'added_by': userdata,
        }
        const settings = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
        };
        const res = await fetch(apihandler, settings);
    }

    const handlenavchanges = (datafinal) => {
        const apihandler = window.harestec_service + `gettopictoChapterDataAPI?topic_id=${datafinal}`
        fetchApiData(apihandler)
    }

    return (
        <>
            <MDBox style={{ width: `${isDesktopOrLaptop == true ? '81%' : '100%'}`, height: '100vh', backgroundColor: "#FDF9F5", overflowY: 'scroll' }}>
                <ResourcesNav datafromnav={handlenavchanges} />
                <MDBox mx={isDesktopOrLaptop == true ? 2 : 0} my={2} style={{ width: '96%' }} id="packages">
                    <MDBox style={{ width: '100%', display: "flex", justifyContent: "center", alignItems: "center", }}>
                        <MDBox style={resourcesforudiv}>
                            <MDBox style={{ width: "100%", height: "80%", }}>
                                <MDBox style={{ width: "100%", display: "block", alignItems: "center", justifyContent: "center" }}>
                                    {dataf?.map((e, index) => (
                                        <MDBox my={2} style={{ width: "100%", display: `${isDesktopOrLaptop == true ? 'flex' : 'block'}`, alignItems: "center", justifyContent: "start", backgroundColor: "#fff" }}>
                                            <MDBox style={{ width: `${isDesktopOrLaptop == true ? '35%' : '100%'}`, height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <img src={window.photourl + 'image/' + e.image_name} style={{ width: "244.53", height: "330px", border: "1px solid #D8D8D8" }} />
                                            </MDBox>
                                            <MDBox style={{ width: `${isDesktopOrLaptop == true ? '65%' : '100%'}`, height: "100%" }}>
                                                <MDTypography mx={2} mt={1} style={{ fontSize: "18px", fontWeight: "600", color: "black", lineHeight: "38.73px" }}>
                                                    {e.name}
                                                </MDTypography>
                                                <MDTypography mx={2} my={2} style={{ fontSize: "14px", fontWeight: "400", lineHeight: "23px", color: "#433C3C" }}>
                                                    {e.descshort}
                                                </MDTypography>
                                                <MDBox mx={2} my={3} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <MDBox style={{ display: "flex", justifyContent: "start", alignItems: "start", width: "70%" }}>
                                                        <MDTypography style={{ fontSize: "18px", fontWeight: "600", lineHeight: "38.73px", color: "#997B64" }}>
                                                            {e.amount_inr} INR / {e.amount_usd} USD
                                                        </MDTypography>
                                                    </MDBox>
                                                    <MDBox style={{ display: "flex", justifyContent: "start", alignItems: "start", width: "30%" }}>
                                                        <MDBox px={3} py={1} style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "40px", backgroundColor: "#2B5F47", color: "#fff" }}>
                                                            <MDTypography style={{ fontSize: "16px", fontWeight: "500", lineHeight: "23px", color: "#fff", cursor: "pointer" }} onClick={(event) => { event.preventDefault(); payment_intiate(e.id, e.amount_inr) }}>Buy</MDTypography>
                                                        </MDBox>
                                                    </MDBox>
                                                </MDBox>
                                            </MDBox>
                                        </MDBox>
                                    ))}
                                </MDBox>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </MDBox>
                <MDBox m={2} style={{ width: '96%', display: "none", justifyContent: "center", alignItems: "center", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" }} id="vcdiv">
                    <MDBox style={{ width: "100%" }}>
                        <MDBox style={{ width: '100%' }}>
                            <MDBox style={{ width: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <MDBox style={{ width: '80%', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <MDTypography style={{ fontSize: "16px", fontWeight: "600", lineHeight: "38.73px", color: "#1E1B1B" }}>Elevate Your Grain Expertise with Lotus Harvestec's Professional Video Consultation Services!"</MDTypography>
                                </MDBox>
                            </MDBox>
                        </MDBox>
                        <MDBox style={{ width: '100%', display: "flex", justifyContent: "center", alignItems: "center", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" }}>
                            <MDBox style={{ width: '80%', display: "flex", justifyContent: "center", alignItems: "center", height: "344px", width: "650px", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px", background: "url('/formbg.jpg')", backgroundRepeat: "round" }}>
                                <MDTypography m={2} style={{ fontSize: "12px", fontWeight: "300", lineHeight: "15px", color: "#fff" }}>
                                    Embark on a journey of technical mastery. Schedule your personalized consultation now to delve deep into the nuances of Food Grain Bulk Storage and Rice Technology. Our seasoned experts stand ready to provide comprehensive insights, address intricate technical queries, and guide you towards optimized solutions. Stay at the forefront of industry knowledge – secure your session today and enhance your proficiency in Food Grain Management and Rice Technology.
                                </MDTypography>
                            </MDBox>
                            <MDBox my={2} style={{ width: "384px", backgroundColor: "#fff", border: "0.5px solid #C4AF89", borderTopRightRadius: "12px", borderBottomRightRadius: "12px", height: "344px" }}>
                                <MDBox mt={3} style={{ width: "100%", height: "39px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <MDBox style={{ width: "90%", height: "39px", display: "flex", justifyContent: "start", alignItems: "center" }}>
                                        <MDTypography style={{ fontSize: "16px", fontWeight: "600", lineHeight: "38.73px", color: "#1E1B1B" }}>Book a video assistance:</MDTypography>
                                    </MDBox>
                                </MDBox>
                                <MDBox my={1} style={{ width: "100%" }}>
                                    <MDBox style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <MDInput type="date" label="" fullWidth id="dateofappointment" required style={{ width: "90%" }} />
                                    </MDBox>
                                    <MDBox my={2} style={{ width: "100%", height: "40%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <MDBox style={{ width: "90%", height: "100%", display: "flex", justifyContent: "start", alignItems: "center" }}>
                                            <MDBox mr={2} style={{ width: "50%", height: "50%", display: "flex", justifyContent: "start", alignItems: "center" }}>
                                                <MDInput type="time" label="" fullWidth id="toappointment" required />
                                            </MDBox>
                                            <MDBox style={{ width: "50%", height: "50%", display: "flex", justifyContent: "end", alignItems: "center" }}>
                                                <MDInput type="time" label="" fullWidth id="fromappointment" required />
                                            </MDBox>
                                        </MDBox>
                                    </MDBox>
                                    <MDBox my={3} style={{ width: "100%", height: "40%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <MDBox style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <MDInput type="text" label="Remarks" fullWidth id="comments" required style={{ width: "90%" }}/>
                                            {/* <textarea id="comments" style={{ width: "90%", height: "100px", backgroundColor: "#FDF9F5", borderRadius: "8px" }} placeholder='Remarks Please' required></textarea>
                                            <style>
                                                {` 
                                            ::placeholder { 
                                                color: black;
                                                text-align: center; 
                                                font-size: 14px;
                                            }`
                                                }
                                            </style> */}
                                        </MDBox>
                                    </MDBox>
                                </MDBox>
                                <MDBox my={1} style={{ width: "100%", height: "40px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <MDBox style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <MDBox style={{ width: "90%", height: "100%", backgroundColor: "#2B5F47", borderRadius: "48px", color: "#fff", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <MDTypography style={{ fontSize: "12px", fontWeight: "500", lineHeight: "21.78px", color: "#FFF" }} onClick={(event) => { event.preventDefault(); submitappointment(); }}>Book appointment</MDTypography>
                                        </MDBox>
                                    </MDBox>
                                </MDBox>
                                <MDBox style={{ width: "100%", height: "40px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <MDBox style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <MDBox style={{ width: "90%", height: "100%", borderRadius: "48px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <MDTypography style={{ fontSize: "10px", fontWeight: "400", lineHeight: "15px", color: "black" }}>Note : Video call will last in 30 minutes</MDTypography>
                                        </MDBox>
                                    </MDBox>
                                </MDBox>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </MDBox >
        </>
    )
}

export default ResourcesBody;