import { useState, useEffect } from "react";
import { ReactReader } from 'react-reader';
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";

function ClientDashboard() {
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");
  const [getApiData, setApiData] = useState();
  const [getDataChap, setDataChap] = useState();

  // get all data 

  const fetchApiData = async (url) => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await fetch(url, settings);
      const rawdata = await res.json();
      fdata.push(rawdata);
      const fdatachap = rawdata.chapter
      setDataChap(fdatachap?.map((i) => i))
      setApiData(fdata?.map((i) => i));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const apihandler = window.harestec_service + `DashboardDataAPI?id=${id}`
    fetchApiData(apihandler);
  }, []);

  const openbook = async (id) => {
    const apihandler = window.curd_service + `?key_params=chapter_id&type=edit&id=${id}`
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await fetch(apihandler, settings);
      const rawdata = await res.json();
    } catch (error) {
      console.log(error);
    }
  }

  const [location, setLocation] = useState(null)
  const locationChanged = epubcifi => {
    // epubcifi is a internal string used by epubjs to point to a location in an epub. It looks like this: epubcfi(/6/6[titlepage]!/4/2/12[pgepubid00003]/3:0)
    setLocation(epubcifi)
  }
console.log(getDataChap);
  return (
    <div style={{ width: "100%", display: "flex" }}>
      <div style={{ backgroundColor: "black", width: "20%", height: "100vh" }}>
        <div className="m-1" style={{ overflow: "auto" }}>
          <MDTypography className="my-3 text-center" variant="h1" color="white" style={{ fontSize: "18px" }}>
            My Purchases
          </MDTypography>
          <MDBox className="w-100">
            {getDataChap?.map((elem) => (
              <Card onClick={(e) => {e.preventDefault(); openbook(elem.type_id)}} style={{ margin: "10 px 5px" }}>
                <CardMedia
                  component="img"
                  alt="chapter image"
                  height="140"
                  image={'https://harvestec.in/image/' + elem.image_name}
                />
                <CardContent>
                  <MDTypography gutterBottom variant="body2" component="div">
                    {elem.name}
                  </MDTypography>
                </CardContent>
              </Card>
            ))}
          </MDBox>
        </div>
      </div>
      <div style={{ height: '100vh', width: "80%" }}>
        <ReactReader
          location={location}
          locationChanged={locationChanged}
          url="https://react-reader.metabits.no/files/alice.epub"
        />
      </div>
    </div>
  );
}

export default ClientDashboard;


