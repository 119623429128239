import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import "jodit/build/jodit.min.css";
import JoditEditor from 'jodit-react';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDInput from "components/MDInput";

const styles = {
  Appheader: {
    backgroundColor: "#ffffff",
    maxHeight: "2vh",
    display: "flex",
    flexDirection: "column",
    fontSize: "calc(10px + 1vmin)",
    color: "black",
    marginBottom: "5vh",
    textAlign: "center",
    padding: "12px",
  },
  App: {
    minHeight: "53vh",
    backgroundColor: "#ffffff",
  },
};



function editquestion() {
  const queryParameters = new URLSearchParams(window.location.search);
  const divison = queryParameters.get("divison");
  const type = queryParameters.get("type");
  const id = queryParameters.get("id");
  const apiwork = queryParameters.get("apiwork");
  const [getIdBasedData, setGetIdBasedData] = useState("");
  const [getParentBasedData, setGetParentBasedData] = useState([]);
  const [getchapBasedData, setGetchapBasedData] = useState([]);
  const [gettopicBasedData, setGettopicBasedData] = useState([]);
  const [getParent, setParent] = useState("");
  const [getTopic, setTopic] = useState("");
  const [getChapter, setChapter] = useState("");
  const [getEditorElemeny, setEditorElement] = useState();
  const editor = useRef(null);
  const [content, setContent] = useState('');
  const [image, setImage] = useState();

  const config =
  {
    placeholder: 'Start typings...'
  }
    ;

  // workinf on work base api

  const fetchApiData = async (url) => {
    const querryname = document.getElementById("querryname").value;
    const descriptionshort = document.getElementById("descriptionshort").value;
    let data = new Object;
    id == 0 ? 
     data = {
      "name": querryname,
      "answer": descriptionshort,
      "added_by": "1",
      "topic_id": getTopic,
    } : data = {
      "id" : id,
      "name": querryname,
      "answer": descriptionshort,
      "added_by": "1",
      "topic_id": getTopic,
    }
    console.log(data);
    const settings = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await fetch(url, settings);
      await res.json();
      // window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  // working on addition

  function adddata() {
    console.log("line no 73");
    const api = window.harestec_service + `${apiwork}`;
    fetchApiData(api);
  }

  // working on update

  function editdata() {
    console.log("line no 73");
    const api = window.harestec_service + `${apiwork}`;
    fetchApiData(api);
  }

  // calling function on the basis of worktype

  function onsubmitdata() {
    { type == "insert" ? adddata() : editdata() }
  }


  // get data on the basis of id 

  const fetcheditApiData = async () => {
    let quarypara = '';
    const typeofwork = 'edit';
    {
      divison == "edittopic" ?
        quarypara = 'topic_id' :
        divison == "editchapter" ?
          quarypara = 'chapter_id' :
          divison == "editsection" ?
            quarypara = 'section_id' : quarypara = 'questions_id'
    }
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await fetch(window.curd_service + `?key_params=${quarypara}&type=${typeofwork}&id=${id}`, settings);
      const apidata = await res.json();
      const fdata = apidata.data;
      console.log("line no - 144", fdata)
      setGetIdBasedData(fdata);
      document.getElementById('querryname').value = fdata[0].name;
      setContent(fdata[0].answer);
      console.log("line no 147")
    } catch (error) {
      console.log(error);
    }
  }

  // get data of parent

  const fetchedparentApiData = async () => {
    let parentquerrydata = '';
    const typeofwork = "select"
    {
      divison == "insert_into_chapter" || divison == "editchapter" ?
        parentquerrydata = 'topics' :
        divison == "insert_into_section" || divison == "editsection" ?
          parentquerrydata = 'chapter' : parentquerrydata = 'section'
    }
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await fetch(window.curd_service + `?key_params=${parentquerrydata}&type=${typeofwork}`, settings);
      const reschap = await fetch(window.curd_service + `?key_params=chapter&type=select`, settings);
      const restopic = await fetch(window.curd_service + `?key_params=topics&type=select`, settings);
      const apidata = await res.json();
      const chapapidata = await reschap.json();
      const topicapidata = await restopic.json();
      const fdata = apidata.data;
      const fdatachap = chapapidata.data;
      const fdatatopic = topicapidata.data;
      setGetParentBasedData(fdata?.map((i) => i));
      setGetchapBasedData(fdatachap?.map((i) => i));
      setGettopicBasedData(fdatatopic?.map((i) => i));
    } catch (error) {
      console.log(error);
    }
  }

  // using of hooks for getting data

  useEffect(() => {
    { id != "0" ? (fetcheditApiData(), fetchedparentApiData()) : (fetchedparentApiData()) };
  }, []);

  // getting parent

  const handleChangeselect = (e) => {
    const parentid = e.target.value;
    setParent(parentid);
  }

  const handletopicChangeselect = (e) => {
    const topicid = e.target.value;
    setTopic(topicid);
  }

  const handleChapChangeselect = (e) => {
    const chapterid = e.target.value;
    setChapter(chapterid);
  }


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDTypography variant="h4" fontWeight="medium" color="dark" mt={1} my={2}>
        {divison == "insert_into_topic" || divison == "edittopic" ?
          "Topics : " :
          divison == "insert_into_chapter" || divison == "editchapter" ?
            "Chapters : " :
            divison == "insert_into_section" || divison == "editsection" ?
              "Section : " : "Questions"
        }
      </MDTypography>
      {divison == "insert_into_topic" || divison == "edittopic" ? (
        ''
      ) : (
        <>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Select topic : 
            </InputLabel>
            <Select
              value={getTopic}
              label="Topic"
              onChange={handletopicChangeselect}
              style={{ height: "45px", marginBottom: "15px" }}
            >
              {gettopicBasedData.map((e, index) => (
                <MenuItem key={index} value={e.id}>{e.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}
      <MDBox mb={2}>
        <MDInput type="text" label={type == "insert" ? 'Question' : divison} fullWidth id="querryname" required/>
      </MDBox>
      <MDBox mb={2}>
        <MDInput type="text" label={type == "insert" ? 'Answer' : "Description"} fullWidth id="descriptionshort" required/>
      </MDBox>
      <Button variant="contained" onClick={onsubmitdata} style={{ color: "#ffffff", float: "right", marginRight: "20px", marginBottom: "25px", backgroundColor: "#3bad21" }}>Submit</Button>

      <Footer />
    </DashboardLayout>
  );
}

export default editquestion;
