import * as React from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Navbar from 'components/Navbar';
import AboutAuthor from 'components/AboutAuthor';
import Footercus from 'components/Footercus';

function AboutAuthormain(){
    return(
        <>
            <Navbar />
            <AboutAuthor />
            <Footercus />
        </>
    )
}

export default AboutAuthormain;