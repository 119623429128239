import Dashboard from "layouts/dashboard";
import Topics from "layouts/topics";
import Chapter from "layouts/chapter";
import Section from "layouts/section";
import Questions from "layouts/questions";
import Users from "layouts/users";
import Package from "layouts/package";
import Media from "layouts/media"; 
import Editmedia from "layouts/editmedia";
import Edit from "layouts/edit";
import Edituser from "layouts/edituser";
import Editquestion from "layouts/editquestion";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Landingpage from "layouts/landingpage";
import Client from "layouts/client";
import Resources from "layouts/Resources"
import AboutAuthormain from "layouts/aboutauthor"
import SignInClient from "layouts/authentication/sign-inclient";
import Detchap from "layouts/detchap";
import Det from "layouts/det";
import Clientdashboard from "layouts/clientdashboard"
import Transaction from "layouts/transaction";
import Activity from "layouts/activity"
import Mytransaction from "layouts/mytansaction"
import Paymentpage from "layouts/paymentpage"
import Mybooks from "layouts/mybooks" 
import Videocalllist from "layouts/videocalllist" 
import Videocallconfirmation from "layouts/videocallconfirmation"
import ServiceForm from "layouts/serviceForm"

// @mui icons
import Icon from "@mui/material/Icon";

let routes = [];

 routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Topics",
    key: "topics",
    icon: <Icon fontSize="small">topic</Icon>,
    route: "/topics",
    component: <Topics />,
  },
  {
    type: "collapse",
    name: "Chapter",
    key: "chapter",
    icon: <Icon fontSize="small">topic</Icon>,
    route: "/chapter",
    component: <Chapter />,
  },
  {
    type: "collapse",
    name: "Section",
    key: "section",
    icon: <Icon fontSize="small">topic</Icon>,
    route: "/Section",
    component: <Section />,
  },
  {
    type: "collapse",
    name: "Questions",
    key: "questions",
    icon: <Icon fontSize="small">topic</Icon>,
    route: "/Questions",
    component: <Questions />,
  },
  {
    type: "collapse",
    name: "Operations",
    key: "edit",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/edit",
    component: <Edit />,
  },
  {
    type: "collapse",
    name: "Operations",
    key: "edituser",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/edituser",
    component: <Edituser />,
  },
  {
    type: "collapse",
    name: "Editquestion",
    key: "editquestion",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/editquestion",
    component: <Editquestion />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Users",
    key: "user",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/users",
    component: <Users />,
  },
  {
    type: "collapse",
    name: "Package",
    key: "package",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/package",
    component: <Package />,
  },
  {
    type: "collapse",
    name: "Add User",
    key: "sign-up",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  {
    type: "collapse",
    name: "Landingpage",
    key: "landingpage",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/landingpage",
    component: <Landingpage />,
  },
  {
    type: "collapse",
    name: "Client",
    key: "client",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/client",
    component: <Client />,
  },
  {
    type: "collapse",
    name: "Resources",
    key: "resources",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/resources",
    component: <Resources />,
  },
  {
    type: "collapse",
    name: "AboutAuthormain",
    key: "aboutAuthormain",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/aboutAuthormain",
    component: <AboutAuthormain />,
  },
  {
    type: "collapse",
    name: "Sign In CLient",
    key: "sign-inclient",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-inclient",
    component: <SignInClient />,
  },
  {
    type: "collapse",
    name: "Media",
    key: "media",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/media",
    component: <Media />,
  },
  {
    type: "collapse",
    name: "Editmedia",
    key: "editmedia",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/editmedia",
    component: <Editmedia />,
  },
  {
    type: "collapse",
    name: "Det",
    key: "det",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/det",
    component: <Det />,
  },
  {
    type: "collapse",
    name: "Detchap",
    key: "detchap",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/detchap",
    component: <Detchap />,
  },
  {
    type: "collapse",
    name: "Clientdashboard",
    key: "clientdashboard",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/clientdashboard",
    component: <Clientdashboard />,
  },
  {
    type: "collapse",
    name: "Transaction",
    key: "transaction",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/transaction",
    component: <Transaction />,
  },
  {
    type: "collapse",
    name: "Activity",
    key: "activity",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/activity",
    component: <Activity />,
  }, 
  {
    type: "collapse",
    name: "Paymentpage",
    key: "paymentpage",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/paymentpage",
    component: <Paymentpage />,
  },
  {
    type: "collapse",
    name: "Mybooks",
    key: "mybooks",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/mybooks",
    component: <Mybooks />,
  }, 
  {
    type: "collapse",
    name: "Video call list",
    key: "videocalllist",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/videocalllist",
    component: <Videocalllist />,
  },
  {
    type: "collapse",
    name: "Video-call-confirmation",
    key: "videocallconfirmation",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/videocallconfirmation",
    component: <Videocallconfirmation />,
  },
  {
    type: "collapse",
    name: "My-Transaction",
    key: "mytransaction",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/mytransaction",
    component: <Mytransaction />,
  },
  {
    type: "collapse",
    name: "Service Form",
    key: "serviceForm",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/serviceForm",
    component: <ServiceForm />,
  }
];

export default routes;
