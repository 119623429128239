import * as React from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Resourcessidenav from 'components/Resourcessidenav';
import ResourcesBody from 'components/ResourcesBody';
import { useState, useEffect } from "react";

function Resources(){

    const [id, setId] = useState(35);

    const handleDataFromChild = (data) => {
        setId(data)
    }

    return(
        <>
            <MDBox style={{width: "100%",height: '100vh', display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Resourcessidenav datafromsidenav={handleDataFromChild}/>
                <ResourcesBody data={id} style={{width: "75%"}}/>
            </MDBox>
        </>
    )
}

export default Resources;