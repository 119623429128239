import * as React from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useMediaQuery } from 'react-responsive'

function Resource() {
    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
    console.log(isDesktopOrLaptop, 'line no 11');
    AOS.init({
        duration: 1200,
    })
    const [dataf, setApiData] = useState();
    const resourcesforudiv = { width: "100%", display: "flex", justifyContent: "center", alignItems: "center" };
    const api = window.curd_service + "?key_params=topics&type=select";
    const fetchApiData = async (url) => {
        const settings = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        };
        try {
            const res = await fetch(url, settings);
            const apidata = await res.json();
            const fdata = apidata.data;
            setApiData(fdata?.map((i) => i));
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchApiData(api);
    }, []);

    const payment_intiate =  (id, amount) => {
        const clientdatastr = localStorage.getItem('client');
        console.log(clientdatastr, 'line no 38');
        if (!clientdatastr || clientdatastr == "undefined") {
            window.open(`/authentication/sign-inclient?type=buy&amount=${amount}&id=${id}&typeof=topics`)
        } else {
            window.open(`/paymentpage?id=${id}&amount=${amount}&type=topics`, "_self")
        }
    }

    return (
        <>
            <MDBox my={5} style={resourcesforudiv}>
                <MDBox style={{ width: "80%", background: "#fff" }}>
                    <MDBox data-aos="fade-right" style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <MDBox style={{ width: "90%", display: `${isDesktopOrLaptop == true ? 'flex' : 'block'}`, alignItems: "center", justifyContent: "center" }}>
                            <MDBox style={{ width: `${isDesktopOrLaptop == true ? '45%' : '100%'}`, height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <img src={
                                        dataf?.map((e, index) => {
                                            if (index == 0) {
                                                return window.photourl + 'image/' + e.image_name
                                            }
                                        }).join('')
                                    } style={{ width: "244.53px", height: "330px", border: "1px solid #D8D8D8" }} />
                            </MDBox>
                            <MDBox style={{ width: `${isDesktopOrLaptop == true ? '55%' : '100%'}`, height: "100%" }}>
                                <MDTypography mx={4} mt={5} style={{ fontSize: "18px", fontWeight: "600", color: "black", lineHeight: "38.73px", fontFamily: "Inter" }}>
                                    {
                                        dataf?.map((e, index) => {
                                            if (index == 0) {
                                                return e.name
                                            }
                                        })
                                    }
                                </MDTypography>
                                <MDTypography mx={4} my={1} style={{ fontSize: "12px", fontWeight: "500", color: "#595959", lineHeight: "24.2px", fontFamily: "Inter" }}>No. of Chapters : 7</MDTypography>
                                <MDTypography mx={4} my={2} style={{ fontSize: "14px", fontWeight: "400", lineHeight: "23px", color: "#433C3C", fontFamily: "Inter" }}>
                                    {
                                        dataf?.map((e, index) => {
                                            if (index == 0) {
                                                return <MDTypography style={{ fontSize: "14px", fontWeight: "400", lineHeight: "23px", color: "#433C3C", fontFamily: "Inter" }}>{e.descshort.substring(0,250)}...</MDTypography>
                                            }
                                        })
                                    }
                                </MDTypography>
                                <a href='/resources'><MDTypography mx={4} my={2} style={{ fontSize: "12px", fontWeight: "400", lineHeight: "23px", color: "#1E1B1B", cursor: "pointer", fontFamily: "Inter" }}>Know more</MDTypography></a>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </MDBox>
            <MDBox my={5} style={resourcesforudiv}>
                <MDBox style={{ width: "80%", background: "#fff" }}>
                    <MDBox data-aos="fade-left" style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <MDBox style={{ width: "90%", display: `${isDesktopOrLaptop == true ? 'flex' : 'block'}`, alignItems: "center", justifyContent: "center" }}>
                            <MDBox style={{ width: `${isDesktopOrLaptop == true ? '45%' : '100%'}`, height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <img src={
                                        dataf?.map((e, index) => {
                                            if (index == 1) {
                                                return window.photourl + 'image/' + e.image_name
                                            }
                                        }).join('')
                                    } style={{ width: "244.53px", height: "330px", border: "1px solid #D8D8D8" }} />
                            </MDBox>
                            <MDBox style={{ width: `${isDesktopOrLaptop == true ? '55%' : '100%'}`, height: "100%" }}>
                                <MDTypography mx={4} mt={5} style={{ fontSize: "18px", fontWeight: "600", color: "black", lineHeight: "38.73px", fontFamily: "Inter" }}>
                                    {
                                        dataf?.map((e, index) => {
                                            if (index == 1) {
                                                return e.name
                                            }
                                        })
                                    }
                                </MDTypography>
                                <MDTypography mx={4} my={1} style={{ fontSize: "12px", fontWeight: "500", color: "#595959", lineHeight: "24.2px", fontFamily: "Inter" }}>No. of Chapters : 11</MDTypography>
                                <MDTypography mx={4} my={2} style={{ fontSize: "14px", fontWeight: "400", lineHeight: "23px", color: "#433C3C", fontFamily: "Inter" }}>
                                    {
                                        dataf?.map((e, index) => {
                                            if (index == 1) {
                                                return <MDTypography style={{ fontSize: "14px", fontWeight: "400", lineHeight: "23px", color: "#433C3C", fontFamily: "Inter" }}>{e.descshort.substring(0,250)}...</MDTypography>
                                            }
                                        })
                                    }
                                </MDTypography>
                                <a href='/resources'><MDTypography mx={4} my={2} style={{ fontSize: "12px", fontWeight: "400", lineHeight: "23px", color: "#1E1B1B", cursor: "pointer", fontFamily: "Inter" }}>Know more</MDTypography></a>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </MDBox>
        </>
    )
}

export default Resource